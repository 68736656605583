import React, { useEffect, useState } from "react";
import { IonModal, IonHeader, IonTitle, IonContent, IonToolbar, IonButtons, IonButton, IonIcon, IonLoading } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import { compress } from "../utils/ImageResize";
import { getAvatarURL } from "../utils/Api";
import VSpace from "./VSpace";

import styles from "./ImagePicker.module.scss";
import ImgWrapper from "./ImgWrapper";

interface IImagePicker {
  initialsrc?: string;
  isOpen: boolean;
  onChange?(file:File|undefined): any;
  onDismiss():any;
}
const ImagePicker: React.FC<IImagePicker> = ({initialsrc, isOpen, onChange, onDismiss}) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const initialURL = initialsrc ? getAvatarURL(initialsrc) : "";
  const [previewUrl, setPreviewUrl] = useState(initialURL);
  const [file, setFile] = useState<File>();
  const [loadingstate, setLoadingstate] = useState(false);

  useEffect(() => {
    if(isOpen){
      openFilePicker();
      // Clear file
      setFile(undefined);
    }
  }, [isOpen]);

  const openFilePicker = () => {
    if(inputRef.current){
      inputRef.current.click();
    }
  }

  const setImage = async (e:any) => {
    setLoadingstate(true);
    const file = e.target.files[0];

    const resizedFile = await compress(file);
    setPreviewUrl(resizedFile.dataurl);
    setFile(resizedFile.file);
    setLoadingstate(false);
  }

  const onReturn = () => {
    if(onChange) onChange(file);
  }

  
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Image Preview</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={onReturn}>
              <IonIcon icon={checkmark} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      <IonLoading
        isOpen={loadingstate}
      />
      <input ref={inputRef} type="file" accept="image/*" onBlur={console.log} onChange={setImage} style={{position: "fixed", top:"-100em"}}/>
      <ImgWrapper maxHeight="80%">
        <img src={previewUrl} width="1000" height="1000" alt="" className={styles.image}/>
      </ImgWrapper>
      <VSpace/>
      <IonButton onClick={openFilePicker} disabled={loadingstate} expand="block">Choose another Image</IonButton>
      </IonContent>
    </IonModal>
  );
}

export default ImagePicker;