import { ITag, IUserData, IChat, IMessage } from "../interfaces/IData";
import { Capacitor } from "@capacitor/core";
import { ApiError } from "../interfaces/IEvents";
import DBService from "../services/DBService";
import { INVALID_RESPONSE } from "./ApiErrors";


const apiVersion = "";
// const apiVersion = "/v1";
const online = true;
const awsUrl = "https://api.everyoneneedsalee.org/v1";
const apiBase = (online || window.location.hostname !== "localhost" || Capacitor.platform !== "web" ? awsUrl : "http://localhost:3001") + apiVersion;

export type Observer<T> = (e:T) => void;

/**
 * Provides an API-Interface with support for Promises or Subscriptions / Callbacks
 */
class ApiRequest<ResponseType> {
  private request: Request;
  protected successObservers: Observer<ResponseType>[] = [];
  protected errorObservers: Observer<ApiError>[] = [];

  constructor(request: Request){
    this.request = request;
  }

  public subscribe(successObserver: Observer<ResponseType>, errorObserver?: Observer<ApiError>){
    this.successObservers.push(successObserver);

    if(errorObserver)
      this.errorObservers.push(errorObserver);

    return this;
  }

  public unsubscribe(successObserver: Observer<ResponseType>, errorObserver?: Observer<ApiError>){
    this.successObservers = this.successObservers.filter(observer => observer !== successObserver);

    if(errorObserver)
      this.errorObservers = this.errorObservers.filter(observer => observer !== errorObserver);

    return this;
  }
  public unsubscribeAll(){
    this.successObservers = [];
    this.errorObservers = [];
  }

  public async run(successObserver?: Observer<ResponseType>, errorObserver?: Observer<ApiError>){
    if(successObserver) this.subscribe(successObserver, errorObserver);

    const response = await fetch(this.request);
    return this.update(response);
  }

  public update(response: Response){
    const responsePromise = convertResponse<ResponseType>(response)
    
    responsePromise.then(res => {
      // console.log(res);
      this.successObservers.forEach(obs => obs(res));
    }).catch(e => {
      this.errorObservers.forEach(obs => obs(e));
    });

    return responsePromise;
  }
}


export interface ApiRegistrationData {
  fullname: string;
  email: string;
  password: string;
  username: string;
  birthdate: string;
  newsletterSubscribed?: boolean;
}

export class ApiRegistrationRequest extends ApiRequest<IUserData>{
  constructor(formData: ApiRegistrationData){
    const request = new Request(apiBase+"/users", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify(formData),
    });

    super(request);
  }
}

export class ApiLoginRequest extends ApiRequest<{user: IUserData; token: string;}>{
  constructor(email: string, password: string){
    const request = new Request(apiBase+"/users/login", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({email, password}),
    })

    super(request);
  }
}

export class ApiLogoutRequest extends ApiRequest<Boolean>{
  constructor(token: string){
    const req = new Request(apiBase+"/users/logout", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({token}),
      headers: {
        Authorization: "Bearer "+token
      },
    });

    super(req);
  }
}


export interface IVerificationResponse{
  message?: string;
}
export class ApiVerifyMailRequest extends ApiRequest<IVerificationResponse>{
  constructor(verificationCode: string, authtoken: string) {
    const request = new Request(apiBase+"/users/verifyMail", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({verificationCode}),
      headers: {
        Authorization: "Bearer "+authtoken
      }
    });

    super(request);
  }
}

export class ApiGetProfileRequest extends ApiRequest<IUserData>{
  constructor(authtoken: string, userid:"me"|string = "me"){
    const request = new Request(`${apiBase}/users/${userid}`, {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer "+authtoken
      }
    });

    super(request);
  }
}

export class ApiUpdateProfileRequest extends ApiRequest<IUserData>{
  constructor(authtoken: string, newData:{[key: string]:any}){
    const request = new Request(`${apiBase}/users/me`, {
      method: "PUT",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer "+authtoken
      },
      body: JSON.stringify(newData)
    });

    super(request);
  }
}

export class ApiGetAvatarUploadUrlRequest extends ApiRequest<any>{
  constructor(authtoken: string, filename: string, filetype: string){
    const request = new Request(`${apiBase}/users/me/updatePicture`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer "+authtoken
      },
      body: JSON.stringify({
        name: filename,
        type: filetype
      })
    });
    super(request);
  }
}


export class S3UploadImageRequest extends ApiRequest<boolean>{
  constructor(uploadurl: string, file: File){
    const req = new Request(uploadurl, {
      method: "PUT",
      cache: "no-cache",
      body: file,
      headers: {
        "Content-Type": file.type
      }
    });

    super(req);
  }
  public update(response: Response){
    if(response.ok){
      this.successObservers.forEach(obs => obs(true));
      return Promise.resolve(true);
    }
    
    const er = new ApiError(response.status, response.statusText);
    this.errorObservers.forEach(obs => obs(er));
    return Promise.reject(er);
  }
}


export class ApiSearchTokensRequest extends ApiRequest<ITag[]>{
  constructor(searchstring: string, authtoken: string){
    const request = new Request(apiBase+"/tags/search/"+searchstring, {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer "+authtoken
      }
    });

    super(request);

    // Put Tags if successful
    this.subscribe((tags) => DBService.putTags(tags));
  }
}

export class ApiSearchMentorsRequest extends ApiRequest<IUserData[]>{
  constructor(tagIds: string[], sexualOrientations: string[], authtoken: string){
    const request = new Request(apiBase+"/mentors/find/", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({tags: tagIds, sexualOrientations: sexualOrientations}),
      headers: {
       Authorization: "Bearer "+authtoken
      }
    });

    super(request);
  }

  // Override update to only return matches and store Tags in DBService
  public update(response: Response){
    const responsePromise = convertResponse<{matches: IUserData[], tags: ITag[]}>(response)
    
    return responsePromise.then(({matches, tags}) => {
      if(!matches || !tags) throw new Error(INVALID_RESPONSE);

      DBService.putTags(tags);
      this.successObservers.forEach(obs => obs(matches));
      return matches;
    }).catch(e => {
      this.errorObservers.forEach(obs => obs(e));
      return e;
    });
  }
}

export class ApiCreateRequestRequest extends ApiRequest<any>{
  constructor(authtoken: string, mentor: string, tags: string[], text: string){
    const req = new Request(apiBase + "/requests", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({
        mentor,
        tags,
        text
      }),
      headers: {
        Authorization: "Bearer " +authtoken
      }
    });

    super(req);
  }
}

export class ApiGetChat extends ApiRequest<IChat>{
  constructor(chatid: string, authtoken: string){
    const request = new Request(apiBase+"/chats/"+chatid, {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer "+authtoken,
      }
    });

    super(request);
  }
}

export class ApiGetFilledUserChats extends ApiRequest<IChat<IUserData>[]>{
  constructor(authtoken: string){
    const request = new Request(apiBase+"/chats", {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer "+authtoken,
      }
    });

    super(request);
  }
}
export class ApiGetUserChats extends ApiRequest<IChat[]>{
  constructor(authtoken: string){
    const request = new Request(apiBase+"/chats", {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer "+authtoken,
      }
    });

    super(request);
  }
  
  // Override update to only return matches and store Tags in DBService
  public update(response: Response){
    const responsePromise = convertResponse<{users: IUserData[], chats: IChat[]}>(response)
    
    return responsePromise.then(({users, chats}) => {
      if(!users || !chats) throw new Error(INVALID_RESPONSE);

      DBService.putUsers(users);
      this.successObservers.forEach(obs => obs(chats));
      return chats;
    }).catch(e => {
      this.errorObservers.forEach(obs => obs(e));
      return e;
    });
  }
}

export class ApiFetchNewMessages extends ApiRequest<IMessage[]>{
  constructor(authtoken: string, timestamp?: Number){
    const stringTimestamp = timestamp !== undefined ? timestamp.toString() : "0";

    const url = apiBase+"/chats/new/"+ encodeURIComponent(stringTimestamp);

    const request = new Request(url, {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer "+authtoken,
      }
    });

    super(request);
  }
}

export const getAvatarURL = (imageid: string) => {
  return `https://app.everyoneneedsalee.org/pictures/${imageid}`;
}

/**
 * Checks if a response is ok
 * If yes, returns response body
 * if no, throws error
 * @param response Response to check
 */
async function convertResponse<T> (response: Response): Promise<T> {
  if(response.status !== 200)
    throw new ApiError(response.status, response.statusText);

  console.log(response);
  return (await response.json() as T);
}

/**
 * Returns a Request with Authorization-Header and cache:no-cache set
 */
class AuthorizedRequest extends Request{
  constructor(input: RequestInfo, authtoken: string, init?: RequestInit){
    super(input, {...init, cache: "no-cache"});
    this.headers.set("Authorization", "Bearer "+authtoken);
  }
}

class _API{
  private errorobservers: ((error:any)=>any)[] = [];
  // private requestQueue: {req: Request, type: string}[] = [];
  // private tickInterval = setInterval(this.queueTick, 500);

  private _authtoken?: string;
  set authtoken(value: string){
    console.log("Set authtoken");
    console.log("----------------");
    this._authtoken = value;
  }

  // private queueTick(){
  //   this.requestQueue.forEach
  // }
  // private enqueue(request: Request, type: string)

  private run<T>(request: Request):Promise<T|null>{
    return fetch(request).then(response => {
      return convertResponse<T>(response);
    }).catch((e) => {
      console.error(e);
      this.errorobservers.forEach(obs => obs(e));
      return null;
    })
  }

  public fetchMessagesFromChat(chatid: string){

  }

  public async fetchMessagesAfterTimestamp(timestamp?: number){
    if(!this._authtoken) return null;
    console.log("Running message update");
    const stringTimestamp = timestamp !== undefined ? timestamp.toString() : "0";
    const url = apiBase+"/chats/new/"+ encodeURIComponent(stringTimestamp);
    const request = new AuthorizedRequest(url, this._authtoken);

    const messages = await this.run<IMessage[]>(request);

    if(!messages) return null;
    return messages.map(message => {
      // Convert strings to real Date object
      message.sentAt = new Date(message.sentAt);
      return message;
    });
  }
  public async sendMessage(chatID: string, text: string){
    if(!this._authtoken) return null;
    const url = apiBase+"/chats/"+chatID;
    const request = new AuthorizedRequest(url, this._authtoken, {
      method: "POST", 
      body: JSON.stringify({text})
    });

    const message = await this.run<IMessage>(request);

    if(!message) return null;
    message.sentAt = new Date(message.sentAt);
    return message;
  }

  public getUser(userID: string){
    // console.log(this._authtoken ? "Authtoken available" : "NO AUTH!");
    // if(!this._authtoken) return null;
    // const request = new AuthorizedRequest(apiBase+"/users/"+userID, this._authtoken);
    // const user = await this.run<IUserData>(request);
    // return user;
    return this._get<IUserData>("users", userID);
  }
  public getChat(chatID: string){
    return this._get<IChat>("chats", chatID);
  }
  private async _get<T>(path: string, ID: string){
    console.log(this._authtoken ? "Authtoken available" : "NO AUTH!");
    if(!this._authtoken) return null;
    const request = new AuthorizedRequest(`${apiBase}/${path}/${ID}`, this._authtoken);
    const response = await this.run<T>(request);
    return response;
  }
}

const API = new _API();
export default API;