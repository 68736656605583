import React, { CSSProperties } from "react";

interface IDecoratorProps {
    height?: string;
    width?: string;
    color?: string;
    background?: string;
    fixed?: boolean;
    absolute?: boolean;
    bottom?: boolean;
    top?: boolean;
}

const Decorator: React.FC<IDecoratorProps> = ({color="rainbow", ...props}) => {
    const styles: CSSProperties = {
        height: props.height || "10px",
        width: props.width || "100%",
        background: props.background || `var(--ion-color-${color})`,
    };

    if(props.fixed){
        styles.position = "fixed";
        styles.left = "0";
    } else if(props.absolute){
        styles.position = "absolute";
        styles.left = "0";
    }
    
    if(props.bottom)
        styles.bottom = "0";
    else if(props.top)
        styles.top = "0";
    

    return (
        <div style={styles}>
        </div>
    );
}


export default Decorator;