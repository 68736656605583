import React from "react";
import useUserID from "../../hooks/useUserID";
import { IMessage } from "../../interfaces/IData";
import Message from "./Message";
import TimeString from "../TimeString";

const MessageContainer: React.FC<{message: IMessage}> = ({message}) => {
  const userID = useUserID();
  const side = message.sender === userID ? "right" : "left";
  const color = side === "right" ? "tertiary" : undefined;
  
  return (
    <Message text={message.text} time={<TimeString date={message.sentAt}/>} side={side} color={color}/>
  );
}

export default MessageContainer;