import React from "react";
import { IonList, IonItem } from "@ionic/react";
import LoadingItem from "./LoadingItem";

const IntelligentList: React.FC<{loading?: boolean; isEmptyElement?: React.ReactNode; isLoadingElement?: Element;}> = ({children, loading, isEmptyElement, isLoadingElement}) => {
  const isEmpty = !children || !(children as any[]).length;

  const content = isEmpty ? 
          (isEmptyElement || <IonItem>This list is empty!</IonItem>) : 
          children;

  return (
    <IonList>
      {loading && (isLoadingElement || <LoadingItem/>)}
      {content}
    </IonList>
  );
}

export default IntelligentList;