import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "./State";
import { ApiGetProfileRequest, ApiFetchNewMessages } from "./utils/Api";
import { USER_DATA_CHANGED, LOGOUT_SUCCESSFUL } from "./actiontypes";
import { IonAlert } from "@ionic/react";
import DBService from "./services/DBService";
import ChatAPI from "./services/ChatAPI";

const ApiRootController: React.FC = () => {
  
  const {appState, dispatch} = useContext(AppContext);
  const [showExpiredAlert, setExpiredAlert] = useState(false);
  const [messageTimer, setMessageTimer] = useState();

  // Redirect on token expire
  useEffect(() => {
    console.log("Expired state changed");
    if(appState.user.loggedIn && appState.user.tokenExpired) setExpiredAlert(true);
  }, [appState.user, setExpiredAlert]);

  // Fetch new messages
  useEffect(() => {
    const updateMessages = () => {
      ChatAPI.updateMessages()
    }

    if(!messageTimer){
      updateMessages();
      // TODO: Remove:
      (window as any).updateMessages = updateMessages;
      setMessageTimer(setInterval(updateMessages, 10000));
    }

    return () => {
      if(messageTimer) clearInterval(messageTimer);
    }
  }, []);

  
  // Update user on reload
  useEffect(() => {
    if(appState.user.loggedIn){
      const req = new ApiGetProfileRequest(appState.user.token)
      req.subscribe((newData) => dispatch({type: USER_DATA_CHANGED, payload: newData}), 
          (error) => console.log("[ERROR] "+error));
      req.run();
      console.log("Updating user data");

      return () => {
        req.unsubscribeAll();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  return (
    <React.Fragment>
      
      <IonAlert
            isOpen={showExpiredAlert}
            onDidDismiss={() => dispatch({type: LOGOUT_SUCCESSFUL})}
            header={"Login Expired"} 
            message={"Your login has expired. Please login again to continue using the app."}
            buttons={["OK"]}
         />
    </React.Fragment>
  );
}

export default ApiRootController;