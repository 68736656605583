import React from "react";
import { FormContextConsumer } from "../../contexts/FormContext";
import {Input, IInput, ICheckbox, Checkbox } from "./Input";

export const ConnectedInput: React.FC<IInput> = (props) => {
  return (
    <FormContextConsumer>
      {context => {
        // {value, type, name, inputmode, position, onChange, className, ...props}
        if(!props.name) return null;
        const {name} = props;

        const newProps = {
          value: props.value || context.values[name],
          onChange: props.onChange || context.onChange,
          position: props.position || context.position,
        }
        // if(name === "email")
        //   console.log(props.value, context.values[name]);
        return <Input {...props} {...newProps}/>
      }}
    </FormContextConsumer>
  );
}

export const ConnectedCheckbox: React.FC<ICheckbox> = (props) => {
  return (
    <FormContextConsumer>
      {context => {
        // {value, type, name, inputmode, position, onChange, className, ...props}
        if(!props.name) return null;
        const {name} = props;

        const newProps = {
          checked: props.checked || context.values[name],
          onChange: props.onChange || context.onChange,
        }
        return <Checkbox {...props} {...newProps}/>
      }}
    </FormContextConsumer>
  );
}

