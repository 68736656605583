import React from "react";
import "./Layouts.scss";
import FlexContainer from "./Flex/FlexContainer";
import FlexItem from "./Flex/FlexItem";


interface ILeeModalProps {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    containerClass?: string;
    contentClass?: string;
}

const LeeModal: React.FC<ILeeModalProps> = (props) => {
    return (
        <FlexContainer className={`modal-container ${props.containerClass || ""}`}>
            {props.header}
            <FlexItem className={props.contentClass || ""} flex="1">
                <div className="modal-scroller">
                    { props.children }
                </div>
            </FlexItem>
            {props.footer}
        </FlexContainer>
    );
}

export default LeeModal;