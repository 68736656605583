import React, { DOMAttributes, CSSProperties } from 'react';
import "./Logo.css";

import fullLogo from "../static/icon.svg";
import handsLogo from "../static/Hands-Logo.png";

const Logo: React.FC<{pStyle?: CSSProperties, height?: any, width?: any, hands?: boolean}> = ({pStyle, height, width, hands}) => {
    const style = {
        ...pStyle,
        width: width || undefined,
        height: height || undefined,
    };

    const src = hands? handsLogo : fullLogo;
    return (<img src={src} style={style} className="lee-logo" alt=""/>);
}

declare module 'react' {
    interface HTMLAttributes<T> extends DOMAttributes<T> {
        width?: string
    }
}

export default Logo;