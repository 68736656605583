
/* eslint no-fallthrough: 0 */
import * as idb from "idb";
import { DBSchema, deleteDB } from "idb";
import { IMessage, ITag, IUserData, IChat } from "../interfaces/IData";

const DATABASE_NAME = "LEE_STORAGE";
const DB_VERSION = 3;



interface LeeDb extends DBSchema {
  "message": {
    key: string;
    value: IMessage;
    indexes: {"date": Date, "chat-time": string}
  },
  "tag": {
    key: string;
    value: ITag;
    indexes: {"name": string};
  },
  "user": {
    key: string;
    value: IUserData;
    indexes: {};
  },
  "chat": {
    key: string;
    value: IChat;
    indexes: {};
  }
}


const dbPromise = idb.openDB<LeeDb>(DATABASE_NAME, DB_VERSION, {
  upgrade(db, oldVersion, newVersion, transaction){
    switch(oldVersion){
      case 0:
        const msgStore = db.createObjectStore("message", {
          keyPath: "_id",
        });
        msgStore.createIndex("date", "sentAt");
        msgStore.createIndex("chat-time", ["chat", "sentAt"]);

        const tagStore = db.createObjectStore("tag", {
          keyPath: "_id",
        });
      case 1:
        const userStore = db.createObjectStore("user", {
          keyPath: "_id",
        })
      case 2:
        const chatStore = db.createObjectStore("chat", {
          keyPath: "_id",
        });
    }
  }
});

/**
 * Provides functions for accessing the local DB and storing different types of data
 */
export default class DBService{
  static async delete(){
    return deleteDB(DATABASE_NAME);
  }
  /**
   * Stores a message in DB and returns promise for transaction
   * @param message Message to store
   */
  static async putMessage(message: IMessage){
    return (await dbPromise).put("message", message);
  }

  /**
   * Stores multiple messages in DB and returns promise for transaction
   * @param messages Array of messages to store
   */
  static async putMessages(messages: IMessage[]){
    const tx = (await dbPromise).transaction("message", "readwrite");
    const msgStore = tx.objectStore("message");

    messages.forEach(message => msgStore.put(message));

    return tx.done;
  }

  /**
   * Gets the latest message from message-store and returns its "sentAt"-value.
   * Use to e.g. retrieve latest messages from server
   */
  static async getLastMsgTimestamp(){
    const tx = (await dbPromise).transaction("message", "readonly");
    const index = tx.store.index("date");
    const cursor = await index.openCursor(undefined, "prev");

    return cursor?.value.sentAt;
  }

    /**
   * Gets the latest message from message-store and returns its "sentAt"-value.
   * Use to e.g. retrieve latest messages from server
   */
  static async getLastMessageFromChat(chatid: string){
    const tx = (await dbPromise).transaction("message", "readonly");
    const index = tx.store.index("chat-time");
    const cursor = await index.openCursor(IDBKeyRange.bound([chatid], [chatid + "\uffff"]), "prev");

    return cursor?.value;
  }

  /**
   * Gets messages from a single chat, returns in ordered array, latest message last
   * @param chatid Id of chat to get messages from
   * @param offset Number of messages to skip before returning / counting messages (pagination)
   * @param number Number of messages to return
   */
  static async getChatMessages(chatid: string, offset = 0, number = 20){
    const tx = (await dbPromise).transaction("message", "readonly");
    const index = tx.store.index("chat-time");
    // Open cursor with entries between chatid & nextchatid (=> chatid + highest possible char: \uFFFF)
    let cursor = await index.openCursor(IDBKeyRange.bound([chatid], [chatid + "\uffff"]), "prev");
    if(cursor === null) return ([] as IMessage[]);

    //offset curser x-items
    if(offset) cursor = await cursor.advance(offset);

    // loop and push results
    const results: IMessage[] = [];
    let i = 0;
    while(cursor && i++ < number){
      results.unshift(cursor.value);
      cursor = await cursor.continue();
    }
    return results;
  }

  /**
   * Puts a single tag in tag-store
   * @param tag Tag to store
   */
  static async putTag(tag: ITag){
    return (await dbPromise).put("tag", tag);
  }

  /**
   * Puts an array of tags in tag-store
   * @param tags Array of tags to store
   */
  static async putTags(tags: ITag[]){
    const tx = (await dbPromise).transaction("tag", "readwrite");

    tags.forEach(tag => tx.store.put(tag));

    return tx.done;
  }

  /**
   * Gets a single tag from tag-store
   * @param tagid Tagid to get
   */
  static async getTag(tagid: string){
    return (await dbPromise).get("tag", tagid);
  }
  static async getTags(tagIds: string[]){
    const tx = (await dbPromise).transaction("tag", "readonly");
    const tags = await Promise.all( 
      tagIds.map((tagid) => tx.store.get(tagid)) 
    );
    return tags.filter((tag): tag is ITag => tag!==undefined);
  }
  static async getAllTags(){
    return (await dbPromise).getAll("tag");
  }


  static async putUser(userData: IUserData){
    return (await dbPromise).put("user", userData);
  }
  static async putUsers(users: IUserData[]){
    const tx = (await dbPromise).transaction("user", "readwrite");

    users.forEach(user => tx.store.put(user));

    return tx.done;
  }

  static async getUser(userid: string){
    return (await dbPromise).get("user", userid);
  }
  static async getUsers(tagIds: string[]){
    const tx = (await dbPromise).transaction("user", "readonly");
    const tags = await Promise.all( 
      tagIds.map((tagid) => tx.store.get(tagid)) 
    );
    return tags.filter((tag): tag is IUserData => tag!==undefined);
  }

  static async putChat(chat: IChat){
    return (await dbPromise).put("chat", chat);
  }
  static async getChat(chatID: string){
    return (await dbPromise).get("chat", chatID);
  }
}