import React, { useEffect } from 'react';
import { IonBackButton, IonHeader, IonPage, IonToolbar, IonContent, IonFooter, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import ROUTES from '../../routes';
import { IMessage, IUserData } from '../../interfaces/IData';
import Avatar from '../../components/Avatar';
import MessageContainer from '../../components/Message/Message.container';

import styles from "./ChatPage.module.scss";
import FlexContainer from '../../layouts/Flex/FlexContainer';
import FlexItem from '../../layouts/Flex/FlexItem';
import MessageInput from '../../components/MessageInput';
import ReversedContent from '../../components/ReversedContent';

interface IChatPage {
  messages: IMessage[];
  partner: IUserData;
  sendMessageHandler(): any;
  handleInputChange(e: Event): any;
  loadMoreMessages(e: any):any;
  inputValue?: string;
  requestSent?: boolean;
  scrollContainerRef: React.RefObject<HTMLIonInfiniteScrollElement>;
}

const ChatPage: React.FC<IChatPage> = ({messages, partner, sendMessageHandler, loadMoreMessages, handleInputChange, inputValue, requestSent, scrollContainerRef}) => {
  console.log(scrollContainerRef);
  return (
    <IonPage>
      <IonHeader className={styles.header}>
        <IonToolbar>
          <FlexContainer row>
            <IonBackButton defaultHref={ROUTES.ME_CHATS} text=""/>
            <Avatar src={partner.profilepicture}/>
            <FlexItem align="center">
              <h2>{partner.username}</h2>
            </FlexItem>
          </FlexContainer>
        </IonToolbar>
      </IonHeader>
      {/* <IonContent> */}
        <ReversedContent loadMore={loadMoreMessages}>
          {/* <IonInfiniteScroll onIonInfinite={loadMoreMessages} threshold="100px" position="top"> */}
            {/* <IonInfiniteScrollContent loadingText="Loading messages..."/> */}
            {messages.map(message => (<MessageContainer key={message._id} message={message}/>))}
          {/* </IonInfiniteScroll> */}
        </ReversedContent>
      {/* </IonContent> */}
      <div ref={(scrollContainerRef as any)}/>
      <IonFooter className={styles.footer}>
        <IonToolbar>
          <MessageInput onChange={handleInputChange} onSubmit={sendMessageHandler} value={inputValue||""} loading={requestSent} />
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ChatPage;
