import { reader } from "ionicons/icons";

export const compress = async (file: File, size=1000, type="image/png", quality=1) => {
  return new Promise<{file: File, dataurl: string}>((resolve, reject) => {
    const fileName = file.name;
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.addEventListener("error", () => reject());

    reader.addEventListener("load", event => {
      if(!event.target) throw new Error("");
      const img = new Image();
      img.src = (event.target.result as string);
      img.addEventListener("load", () => {
        const height = img.height;
        const width = img.width;
        const offset = (height>width ? (height-width) : (width-height)) / 2;
        const xOffset = width>height ? offset : 0;
        const yOffset = height>width ? offset : 0;
        const realSize = height > width ? width : height;

        console.log(height, width, offset, realSize);

        // Create canvas with matching proportions
        const canvas = document.createElement("canvas");
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext("2d");
        if(!ctx) throw new Error("Couldnt create canvas ctx");
        ctx.drawImage(img, xOffset, yOffset, realSize, realSize, 0, 0, size, size);

        const dataurl = canvas.toDataURL("image/png")
        ctx.canvas.toBlob((blob) => {
          if(!blob) throw new Error("Couldnt create blob");

          const file = new File([blob], fileName, {
            type,
            lastModified: Date.now(),
          });

          console.log(file);
          resolve({file, dataurl});
        });
      });
    });
  });
}