import React, { Component } from "react";
import { IonChip, IonIcon, createAnimation } from "@ionic/react";
import { ITag } from "../interfaces/IData";
import { closeCircle } from "ionicons/icons";

import { popInAnimation } from "../theme/animation/animations";

interface Props{
  tag: ITag;
  color?:string;
  animated?: boolean;
  onClose?(tagid: string):any;
}

interface State{
  unmounting: boolean;
}

export default class Tag extends Component<Props, State> {
  readonly state: State = {
    unmounting: false,
  }
  private tagRef = React.createRef<HTMLIonChipElement>();

  componentDidMount(){
    if(this.props.animated !== false) this.animateIn();
  }

  animateIn = async () => {
    if(!this.tagRef.current) return;

    return popInAnimation()
      .addElement(this.tagRef.current)
      .play();
  }

  animateOut = async () => {
    if(!this.tagRef.current) return;

    return createAnimation()
      .addElement(this.tagRef.current)
      .duration(150)
      .keyframes([
        {offset: 0, opacity: 1},
        {offset: 1, opacity: 0}
      ])
      .play();
    
  }

  handleClick = async () => {
    if(this.props.onClose) {
      if(this.props.animated !== false)
        await this.animateOut();
      this.props.onClose(this.props.tag._id);
    }
  }

  render() {
    const {color, tag, onClose} = this.props;

    return (
      // <IonChip ref={this.tagRef} onAnimationEnd={this.animationEnd} className={this.state.unmounting ? styles.animationOut : styles.animationIn} color={color} key={tag._id} onClick={this.startAnimationOut} >
      <IonChip ref={this.tagRef} color={color} key={tag._id} onClick={this.handleClick} >
        {tag.name}
        {onClose && <IonIcon icon={closeCircle} />}
      </IonChip>);
  }
}
// const Tag: React.FC<{tag: ITag, clickHandler?(tagid: string):any, color?:string}> = ({color, tag, clickHandler}) => {
//   return (
//     <IonChip color={color} key={tag._id} onClick={clickHandler?.bind(null, tag._id)} >
//       {tag.name}
//       {clickHandler && <IonIcon icon={closeCircle} />}
//     </IonChip>);
// }

// export default Tag;