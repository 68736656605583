export interface NavigationEvent{
  direction: "forwards"|"backwards";
  payload?:any;
}

export interface ApiError extends Error{
  status: number;
}

export class ApiError extends Error{
  /**
     * Error for API requests
     * @param statusCode Status of the response
     * @param message Errormessage of the response
     */
  constructor(statusCode:number, message?:string){
      super(message);
      this.status = statusCode;
  }
}