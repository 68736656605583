import React from 'react';

import styles from "./Avatar.module.scss";
import { getAvatarURL } from '../utils/Api';
import { IonAvatar } from '@ionic/react';

interface IProfilePicture{
  onClick?():any;
  size?: number;
  src?: string;
  style?: any;
  slot?: string;
  className?: string;
  fill?: boolean;
}

const Avatar: React.FC<IProfilePicture> = ({style, size, fill, onClick, slot, className, ...props}) => {
  
  const src = props.src ? 
    getAvatarURL(props.src)
    : `https://api.adorable.io/avatars/${size||200}/1234`;

  return (
    <IonAvatar slot={slot} className={`${fill? styles.fill : ""} ${className}`}>
      <img
       style={style}
        src={src} 
        alt="" 
        onClick={onClick}
      />
    </IonAvatar>
  );
}

// export default withRouter(ProfilePicture);
export default Avatar