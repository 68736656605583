import React, { useState, useEffect, useContext } from 'react';
import { IonButton, IonIcon} from '@ionic/react';
import Logo from '../components/Logo';
import LeeModal from '../layouts/ModalPage';
import SimpleHeader from '../components/SimpleHeader';
import { RouteComponentProps, Route, Redirect, Switch } from 'react-router';
import ROUTES from '../routes';

import { NavigationEvent } from '../interfaces/IEvents';
import { verifyEmail, verifyFullname, verifyBirtdate, verifyUsername } from '../utils/Verify';
import { FormContextProvider } from '../contexts/FormContext';

import RegisterForm1 from './registration/RegistrationForm1';
import RegisterForm2 from './registration/RegistrationForm2';
import RegisterForm3 from './registration/RegistrationForm3';
import Decorator from '../components/Decorator';
import VSpace from '../components/VSpace';
import { ApiRegistrationData, ApiRegistrationRequest } from '../utils/Api';
import { AppContext } from '../State';
import { LOGIN_SUCCESSFUL } from '../actiontypes';
import { arrowBack } from 'ionicons/icons';

interface IMatchParams {
  formid: string;
}

interface IRegisterProps extends RouteComponentProps<IMatchParams> {
  // Additional props

}

const RegisterModal: React.FC<IRegisterProps> = ({match, history}) => {
  const { dispatch } = useContext(AppContext);

  const [state, setState] = useState({
		email: "",
		email_valid: true,
		password: "",
		password_valid: true,
		password2: "",
		password2_valid: true,
		username: "",
		username_valid: true,
		fullname: "",
		fullname_valid: true,
		birthdate: "",
		birthdate_valid: true,
		termsAccepted: false,
		formSent: false,
  });

  const formPhase = Number(match.params.formid);

  // Set to 1 on pageload
  useEffect(() => {
		// Activate after testing:
		history.replace(ROUTES.REGISTER+"/1");
	//eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: CustomEvent<any>) => {
		const target = (e.target as HTMLInputElement);
		const newVal = target.tagName.search("CHECKBOX") === -1 ? target.value : target.checked;
		setState({
			  ...state,
			  [target.name]:  newVal,
		});
		console.log(e);
		console.log({[target.name]: newVal,})
  }

  const handleNavigate = (e: NavigationEvent) => {
		if(e.direction === "forwards"){
			console.log("FOrwards");
			console.log(state);

			// Check phase 1
			if(formPhase === 1){
				let phaseOK = true;
				if(verifyEmail(state.email) !== "ok"){
						phaseOK = false;
						setState({...state, email_valid: false})
				}
				if(state.password !== state.password2){
						phaseOK = false;
						setState({...state, password2_valid: false})
				}

				if(phaseOK) {
						history.push(ROUTES.REGISTER+"/2")
				};
			}
			else if(formPhase === 2){
				let phaseOK = true;
				if(verifyFullname(state.fullname) !== "ok"){
  					console.log(verifyFullname(state.fullname));
						phaseOK = false;
						setState({...state, fullname_valid: false});
				}
				if(verifyBirtdate(state.birthdate) !== "ok"){
						phaseOK = false;
						setState({...state, birthdate_valid: false});
				}
				if(verifyUsername(state.username) !== "ok"){
						phaseOK = false;
						setState({...state, username_valid: false})
				}
				if(phaseOK){
						history.push(ROUTES.REGISTER+"/3");
				};
			}
			else if(formPhase === 3){
				if(state.termsAccepted){
					const registerData = {
						email: state.email,
						password: state.password,
						username: state.username,
						fullname: state.fullname,
						birthdate: state.birthdate,
					};
					setState({...state, formSent: true});
					sendForm(registerData).then(successful => {
						if(successful)
							history.push(ROUTES.REGISTER+"/4");
						else
							setState({...state, formSent: false});
					});
					
				}
			}
		}
	}

	const sendForm = (formData: ApiRegistrationData) => {
		const req = new ApiRegistrationRequest(formData);
		return req.run().then((r) => {
			dispatch({
				type: LOGIN_SUCCESSFUL,
				payload: r
			});
			return true;
		})
		.catch((e) => {
			console.log(e);
			return false;
		});

	}
	
	const handleBack = () => {
		if(formPhase === 1)
			history.push(ROUTES.LOGIN);
		else{
			history.goBack();
		}
		return;
  }

  return (
	<LeeModal contentClass="text-center" header={<SimpleHeader left={<IonButton fill="clear" onClick={handleBack}><IonIcon slot="icon-only" icon={arrowBack}/></IonButton>} right={<Logo width="50px"/>}/>}>
	  	<FormContextProvider values={state} onChange={handleChange} onNavigation={handleNavigate}>
			<Switch>
				<Route path={ROUTES.REGISTER+"/1"} component={RegisterForm1}/>
				<Route path={ROUTES.REGISTER+"/2"} component={RegisterForm2}/>
				<Route path={ROUTES.REGISTER+"/3"} component={RegisterForm3}/>
				<Redirect exact from={ROUTES.REGISTER+"/4"} to={ROUTES.ACTIVATE_ACCOUNT}/>
			</Switch>
		</FormContextProvider>
		<VSpace/>
		<Decorator absolute bottom/>
	</LeeModal>
  );
};

export default RegisterModal;
