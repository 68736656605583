import React, { useContext } from "react";
import { AppContext } from "../State";
import { ApiLogoutRequest } from "../utils/Api";
import { IonButton, IonIcon } from "@ionic/react";
import { lockClosed } from "ionicons/icons";
import { LOGOUT_SUCCESSFUL } from "../actiontypes";

const LogoutButton: React.FC = () => {
  const {appState, dispatch} = useContext(AppContext);

  const onLogoutClick = (e:any) => {
    new ApiLogoutRequest(appState.user.token).run((r) => {
      dispatch({type: LOGOUT_SUCCESSFUL});
    });
  }

  if(appState.user.loggedIn)
    return (
      <IonButton expand="block" fill="outline" color="quaternary" onClick={ onLogoutClick }>
        <IonIcon slot="start" size="small" icon={lockClosed}/>
        Logout
      </IonButton>
    );
  else
    return null;
}

export default LogoutButton;