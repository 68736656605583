import React, { useState, useContext } from "react";
import { IonContent, IonSlides, IonSlide, IonButtons, IonButton, IonIcon, IonTextarea, IonLabel, IonItem, IonText } from "@ionic/react";

import SimpleModal from "../SimpleModal";
import { send } from "ionicons/icons";
import { ITag, IUserData } from "../../interfaces/IData";
import ContentWrapper from "../ContentWrapper";
import { userInfo } from "os";
import Tag from "../Tag";
import { ApiCreateRequestRequest } from "../../utils/Api";
import { AppContext } from "../../State";

interface IProfileViewer{
  isOpen: boolean;
  mentor?: IUserData;
  onDismiss?(): any;
  onSuccess?(): any;
  title?: string;
  presentingElement?: HTMLElement;
  tags: ITag[];
}

const RequestForm: React.FC<IProfileViewer> = ({isOpen, onDismiss, mentor, title, children, tags, onSuccess, ...props}) => {    
  const [formstate, setFormstate] = useState<{message?:string}>();
  const { appState } = useContext(AppContext);
  if(!mentor) return null;

  const handleChange = (e:CustomEvent<any>) => {
    const target = (e.target as HTMLInputElement);
    if(!target) return;

    setFormstate({...formstate, [target.name]: target.value});
  }

  const sendRequest = async () => {
    if(!formstate?.message) return;

    new ApiCreateRequestRequest(appState.user.token, mentor._id, tags.map(tag => tag._id), formstate.message).run()
      .then((r) => {
        console.log(r);
        if(onSuccess) onSuccess();
      })
      .catch(console.log);
  }

  return (
    <SimpleModal title={title||"Your request"} isOpen={isOpen} onDismiss={onDismiss} header={
      <IonButtons slot="end">
        <IonButton onClick={sendRequest}>
          <IonIcon icon={send}/>
        </IonButton>
      </IonButtons>
    }>
      <ContentWrapper>
        <IonText>Write a friendly message to {mentor.username}</IonText>
        <IonItem lines="none">
          <IonTextarea name="message" onIonChange={handleChange} placeholder="Type here" rows={10} autoGrow autoCorrect=""></IonTextarea>
        </IonItem>
        <p>Topics you want to talk about</p>
        {tags.map(tag => <Tag tag={tag}/>)}
      </ContentWrapper>
    </SimpleModal>
  );
}

export default RequestForm;