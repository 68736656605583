import { IChat, IMessage } from "../interfaces/IData";
import DBService from "./DBService";
import API from "../utils/Api";

export type ChatObserverFunc = (chat: IChat) => void;
export type ChatObserver = {func: ChatObserverFunc, chatid: string};

export type MessageObserverFunc = () => void;

class _ChatAPI{
  private chatobservers: ChatObserver[] = [];
  private messageobservers: MessageObserverFunc[] = [];
  private requests: {type: string, id?: string, promise: Promise<any>}[] = [];

  public subscribeToChat(chatID: string, handleChatChange: ChatObserverFunc){
    // const observer = ;
    this.chatobservers.push({chatid: chatID, func: handleChatChange});
    this._lookup(chatID, handleChatChange);
    // return observer;
    // this.getChat(chatID);
  }
  public unsubscribeFromChat(chatID: string, observerToRemove: ChatObserverFunc) {
    this.chatobservers = this.chatobservers.filter(observer => observer.chatid !== chatID || observer.func !== observerToRemove);
  }
  private _updateChat(chat: IChat){
    this.chatobservers.forEach(observer => {
      if(observer.chatid === chat._id || observer.chatid === "*")
        observer.func(chat);
    });
  }
  public getChat(chatID: string){
    API.getChat(chatID).then(chat => {
      if(!chat) return;
      DBService.putChat(chat);
      this._updateChat(chat);
    })
  }
  private async _lookup(chatID: string, obsToInitialize: ChatObserverFunc){
    // If chat is cached, initialize observer with that
    const cacheChat = await DBService.getChat(chatID);
    if(cacheChat) {
      obsToInitialize(cacheChat);
      return;
    }

    // Otherwise check if request is running
    const req = this.requests.find(request => (
      request.type === "getChat"
      && request.id === chatID
    ));
    // and if not start a new one
    if(!req) this.getChat(chatID);
  }

  public listenToMessageUpdate(observer: MessageObserverFunc){
    this.messageobservers.push(observer);
  }
  public dismissMessageUpdate(observerToRemove: MessageObserverFunc){
    this.messageobservers = this.messageobservers.filter(observer => observer !== observerToRemove);
  }
  private _updateMessages(messages: IMessage[]){
    DBService.putMessages(messages).then(() => {
      this.messageobservers.forEach(obs => obs());
    });
  }

  public updateMessages(){
    const run = async () => {
      console.log("Running");
      const date = await DBService.getLastMsgTimestamp();
      console.log(date);
      const messages = await API.fetchMessagesAfterTimestamp(date ? date.getTime() : 0);
      console.log(messages);

      if(!messages) return;
      this._updateMessages(messages);
    }
    console.log("Starting update Messages");
    run();
  }

  public async sendMessage(chatID: string, text: string){
    API.sendMessage(chatID, text).then(message => {
      if(!message) return;
      this._updateMessages([message]);
    })
  }

}

const ChatAPI = new _ChatAPI();
export default ChatAPI;