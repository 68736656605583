import React, { useEffect, useState } from 'react';
import {
  IonApp
} from '@ionic/react';
import { Plugins, Capacitor } from "@capacitor/core";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import "./theme/App.scss";

/* App Context */
import { AppContextProvider, getStoredData } from './State';

/* Components */
import ApiRootController from './ApiRootController';
import AppRootOutlet from './AppRootOutlet';
import { IState } from './interfaces/IState';



const {SplashScreen} = Plugins;


const App: React.FC = () => {
  const [storedData, setStoredData] = useState<IState|{}>();

  if(Capacitor.platform !== "web")
    SplashScreen.hide();

  
  useEffect(() => {
    getStoredData().then(data => {
      setStoredData(data)
    });    
  }, [])

  // await stored data
  if(storedData === undefined) return null;

  return (
    <IonApp>
      <AppContextProvider initial={storedData}>
        {/* Update user data & Validate Token */}
        <ApiRootController/>

        {/* Outlet for Page */}
        <AppRootOutlet/>
      </AppContextProvider>
    </IonApp>
  );
};

export default App;
