import React, { createContext, useReducer, useEffect } from "react";
import rootReducer from "./reducers";
import { IState, IContextProps } from "./interfaces/IState";

import { Plugins, Capacitor } from "@capacitor/core";
import DBService from "./services/DBService";
const { Storage } = Plugins;

const STORAGE_KEY = "leeStorage";

const initialState: IState = {
  user: {
    loggedIn: false,
    storeSession: true,
    // Just to keep typescript quiet
    data: {
      _id: "0",
      username: "Loading...",
      showBirthdate: false,
    },
  },
  window: {
    tabbarVisible: true,
  },
  reset: false,
}


// TODO: Implement real persistent storage
export const getStoredData: ()=>Promise<IState|{}> = async () => {
  try{
    const item = await Storage.get({key: STORAGE_KEY});
    if(!item.value) throw new Error("Failed parsing state from storage");
    console.log("Retrieved state from storage");

    return JSON.parse(item.value);
  } catch(e){
    console.error(e.message);
    return {};
  }
}

let AppContext = createContext({} as IContextProps);


function AppContextProvider(props:any) {
  // const addedState = (persistedState.lastupdate >= sessionState.lastupdate) ? persistedState : sessionState;
  // const addedState = (await getStoredData())||{};
  const addedState = props.initial || {};

  const fullInitialState: IState = {
    ...initialState,
    ...addedState,
  };

  console.log("Initial state:");
  console.log(fullInitialState);

  let [state, dispatch] = useReducer(rootReducer, fullInitialState);

  useEffect(() => {
    //Check if state should reset:
    if(state.reset){
      console.log("-------- RESET ACTIVATED --------");
      const promises = [
        Storage.remove({key: STORAGE_KEY}),
        DBService.delete()
      ];
      Promise.all(promises).then(() => {
        window.location.replace(window.location.origin);
      })
      // Return to prevent storing new state in storage while reloading
      return;
    }

    console.log("User "+ (state.user.storeSession?"agreed":"denied") + " permanently storing data.");
    console.log(state.user)
    const dataToStore = JSON.stringify({
      user: state.user,
      lastupdate: Date.now()
    });

    Storage.set({key: STORAGE_KEY, value: dataToStore});
    // if(state.user.storeSession){
    //   console.log("Storing in persistent storage");
    //   window.localStorage['leeStorage'] = dataToStore;
    // }
    // else{
    //   console.log("Storing in session storage");
    //   window.sessionStorage.setItem("leeSession", dataToStore);
    // }
  }, [state]);

  let value = {appState: state, dispatch };
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };