import React, { useState, useContext } from 'react';
import { IonInput, IonItem, IonLabel, IonButton, IonCheckbox } from '@ionic/react';
import Logo from '../components/Logo';
import ROUTES from '../routes';
import LeeModal from '../layouts/ModalPage';
import Decorator from '../components/Decorator';
import { ApiLoginRequest } from '../utils/Api';
import { AppContext } from '../State';
import { LOGIN_SUCCESSFUL } from '../actiontypes';
import { RouteComponentProps } from 'react-router';

const LoginModal: React.FC<RouteComponentProps> = ({history}) => {
    const { dispatch } = useContext(AppContext);

    const [state, setState] = useState({
        email: "",
        password: "", 
        rememberCheckbox: false,
        formSent: false,
    });

    // const login = async (email: string, password: string) => {
    //     const result = await fetch("");
    //     return await result.json();
    // }

    const handleChange = (e: CustomEvent<KeyboardEvent>) => {
        const target = (e.target as HTMLInputElement);
        setState({
            ...state,
            [target.name]: target.value,
        });
    }

    const toggleRemember = (e: any) => {
        const checkboxState = (e.target as HTMLIonCheckboxElement).checked;
        
        setState({
            ...state,
            rememberCheckbox: checkboxState,
        });
    }

    const submitLogin = () => {
        setState({...state, formSent: true});

        new ApiLoginRequest(state.email, state.password).run((userData) => {
            dispatch({
                type: LOGIN_SUCCESSFUL,
                payload: {...userData, storeSession: state.rememberCheckbox}
            });
            history.push(ROUTES.ROOT);
        }, (error) => {
            console.log("[ERROR] "+error);
        });
    }

    return (
        <LeeModal>
            <div className="text-center">
                <Logo width="25vh"/>
            </div>
                <IonItem>
                    <IonLabel position="floating">E-Mail</IonLabel>
                    <IonInput
                        type="email" 
                        name="email" 
                        inputmode="email" 
                        onIonInput={handleChange}
                        />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput 
                        type="password" 
                        name="password" 
                        inputmode="text" 
                        onIonInput={handleChange}
                        />
                </IonItem>
                <IonItem lines="none">
                    <IonCheckbox onClick={toggleRemember}/>
                    <IonLabel><small>&nbsp;Remember me on this device</small></IonLabel>
                </IonItem>
                <IonButton class="lee-login-submit" expand="block" disabled={state.formSent} onClick={submitLogin} type="submit">Login</IonButton>
                
                <div>
                    <IonButton routerLink={ROUTES.FORGOT_PASSWD} size="small" fill="clear"><small>Forgot your password?</small></IonButton>
                    <IonButton routerLink={ROUTES.REGISTER} class="float-right" size="small" fill="clear"><small>Not registered yet?</small></IonButton>
                </div>
                
            <Decorator absolute bottom/>
        </LeeModal>
    );
};

export default LoginModal;