import React, { useState, useEffect } from "react";
import { IonInfiniteScroll, IonContent } from "@ionic/react";

const ReversedContent: React.FC<{loadMore?(...arg:any):any}> = ({children, loadMore}) => {
  const ref = React.createRef<HTMLIonContentElement>();
  const [pos, setPos] = useState(0);

  useEffect(() => {
    if(!ref.current) return;
    const scrollContainer = ref.current.shadowRoot?.querySelector("main");
    scrollContainer?.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);

  useEffect(() => {
    if(!ref.current) return;
    const scrollContainer = ref.current.shadowRoot?.querySelector("main");
    if(!scrollContainer) return;
    console.log("Scrolling...");
    scrollContainer.scrollTo(0, scrollContainer.scrollHeight - pos);
  });

  const handleScroll = () => {
    console.log("Handling scroll");
    if(!ref.current) return;
    const scrollContainer = ref.current.shadowRoot?.querySelector("main");
    if(!scrollContainer) return;

    console.log(scrollContainer.scrollTop);
    setPos(scrollContainer.scrollHeight - scrollContainer.scrollTop);

    if(scrollContainer.scrollTop < 100)
      if(loadMore) loadMore();
  }

  return (
    <IonContent ref={ref}>
      {children}
    </IonContent>
  );
}

export default ReversedContent;