import React, { useState } from "react";
import { IonSearchbar, IonList, IonItem } from "@ionic/react"

// import React, { Component, useState } from "react";
import "./ComboField.scss";
import LoadingItem from "./LoadingItem";
import { ITag } from "../interfaces/IData";

interface IComboProps {
  loader?: boolean;
  proposals?: ITag[];
  handleChange?(event: CustomEvent<any>): any;
  onSelect?(event: any): any;
}

const ComboField: React.FC<IComboProps> = ({loader, proposals, handleChange, onSelect}) => {

  const [state, setState] = useState({focus: false,});
  const inputRef = React.createRef<HTMLIonSearchbarElement>();

  const handleFocus = () => {
    setState({...state, focus: true});
  };

  const handleLeave = () => {
    setState({...state, focus: false});
  }


  const renderProposals = (proposals || []).map(tag => {
    // console.log(proposals);
    return <IonItem key={tag._id} onClick={(e) => {onSelect && onSelect(tag._id); console.log(tag._id)}} button>{tag.name}</IonItem>
  });

  return (
    <div className={`lee-combofield-container ${state.focus?"hasFocus":""}`} onClick={() => {inputRef.current?.setFocus()}}>
      <IonSearchbar onIonChange={handleChange} onIonFocus={handleFocus} onIonBlur={handleLeave} ref={inputRef}/>
      <IonList lines="full">
        {renderProposals}
        {loader && <LoadingItem/>}
      </IonList>
    </div>
  );
}

export default ComboField;