import React from "react";

const ContentWrapper: React.FC<{className?: string}> = ({children, className}) => {
  
  return (
    <div style={{padding: "15px 25px"}} className={className}>
      {children}
    </div>
  );
}

export default ContentWrapper;