import { IReducer } from "./interfaces/IState";
import { SHOW_TABBAR, HIDE_TABBAR, TOGGLE_TABBAR } from "./actiontypes";

export const WindowReducer: IReducer = (state, action) => {
  switch(action.type){
    case SHOW_TABBAR:
      return {
        ...state,
        window: {
          ...state.window,
          tabbarVisible: true
        }
      };
      case HIDE_TABBAR:
        return {
          ...state,
          window: {
            ...state.window,
            tabbarVisible: false
          }
        };
      case TOGGLE_TABBAR:
        return {
          ...state,
          window: {
            ...state.window,
            tabbarVisible: !state.window.tabbarVisible
          }
        };
      default:
        return state;
  }
}