import React from "react";
import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonContent, IonList, IonListHeader, IonItem, IonIcon } from "@ionic/react";
import ROUTES from "../../routes";
import { settings, key, notifications, helpCircle, people } from "ionicons/icons";
import LogoutButton from "../../components/LogoutButton";

const Settings: React.FC = () => {
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={ROUTES.ME_ME}/>
          </IonButtons>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      <IonList lines="full">
          <IonListHeader>
            Settings
          </IonListHeader>
          <IonItem button routerLink={ROUTES.ME_EDIT}>
            <IonIcon icon={key} slot="start" color="medium"/>
            Account
          </IonItem>
          <IonItem button>
            <IonIcon icon={notifications} slot="start" color="medium"/>
            Notifications
          </IonItem>
          <IonItem button>
            <IonIcon icon={settings} slot="start" color="medium"/>
            Settings
          </IonItem>
          <IonItem button>
            <IonIcon icon={helpCircle} slot="start" color="medium"/>
            Legal & Contact
          </IonItem>
          <IonItem button>
            <IonIcon icon={people} slot="start" color="medium"/>
            Tell friends
          </IonItem>
        </IonList>
        <p className="placeholder">{process.env.REACT_APP_BUILDSTAMP||"No buildnr provided"}</p>
        {/* <VSpace small/> */}
        <LogoutButton/>
      </IonContent>
    </IonPage>
  );
}

export default Settings;