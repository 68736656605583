import React, { useState, useEffect, useContext } from "react";
import { IonItem } from "@ionic/react";
import Avatar from "./Avatar";
import { IUserData, IMessage } from "../interfaces/IData";
import DBService from "../services/DBService";
import { AppContext } from "../State";
import ChatAPI from "../services/ChatAPI";
import UserAPI from "../services/UserAPI";
import ROUTES from "../routes";
import useChat from "../hooks/useChat";
import TimeString from "./TimeString";


const ChatItem: React.FC<{chatID: string}> = ({chatID}) => {
  const { appState, } = useContext(AppContext);
  
  const chat = useChat(chatID);
  const [chatpartner, setChatpartner] = useState<IUserData>();
  const [lastmessage, setLastmessage] = useState<IMessage>();

  useEffect(() => {
    if(!chat) return;

    const partnerid = chat.users.find(userid => userid !== appState.user.data?._id);
    if(!partnerid) return;

    UserAPI.getUser(partnerid).then(user => {
      if(!user) return;
      setChatpartner(user);
    });

    const getLastMessage = async () => {
      DBService.getLastMessageFromChat(chat._id).then((message) => {
        if(message && (!lastmessage || lastmessage._id !== message._id))
          setLastmessage(message);
      })
    }


    ChatAPI.listenToMessageUpdate(getLastMessage);
    getLastMessage();

    return () => {
      ChatAPI.dismissMessageUpdate(getLastMessage);
    }
  }, [chat])

  return (
    <IonItem routerLink={ROUTES.ME_CHATS+"/"+chatID}>
          <Avatar src={chatpartner?.profilepicture} slot="start"/>
          <div style={{width: "100%"}}>
            <h3>{chatpartner?.username || "Loading..."}</h3>
            {lastmessage ? 
              <p style={{fontSize: ".8em", marginTop: "4px",}}>
                {lastmessage.text} <span className="placeholder" style={{float: "right", marginRight: "20px"}}><TimeString date={lastmessage.sentAt}/></span>
              </p>
              : <p className="placeholder" style={{fontSize: ".8em", marginTop: "4px"}}>This chat is empty.</p>
            }
          </div>
    </IonItem>
  );
}

export default ChatItem;