import React, { useState, useEffect } from "react";
import { IonItem } from "@ionic/react";
import FlexContainer from "../layouts/Flex/FlexContainer";
import Avatar from "./Avatar";
import FlexItem from "../layouts/Flex/FlexItem";
import Tag from "./Tag";
import { IUserData, ITag } from "../interfaces/IData";
import DBService from "../services/DBService";

const MentorItem: React.FC<{user: IUserData, onClick?():any}> = ({user, onClick}) => {
  const [tags, setTags] = useState<ITag[]>([]);

  // Load Tags from storage
  //TODO: Exlude Tags from matching mentors on server side
  useEffect(() => {
    if(!user.tags) return;
    DBService.getTags(user.tags).then(tags => {
      setTags(tags);
    })
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(tags);
  return (
    <IonItem onClick={onClick}>
        <FlexContainer row fill style={{padding: "10px 0"}}>
          <Avatar src={user.profilepicture} size={50}/>
          <FlexItem>
            <h3>{user.username}</h3>
            {user.about ? <p>{user.about}</p> : <p className="no-bio">This user currently has no bio.</p>}
            <p>Topics: {tags.map(tag => <Tag key={tag._id} tag={tag}/>)}</p>
          </FlexItem>
        </FlexContainer>
    </IonItem>
  );
}

export default MentorItem;