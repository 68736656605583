import { IAction, IState, IReducer } from "./interfaces/IState";
import { AuthReducer } from "./AuthReducer";
import { WindowReducer } from "./WindowReducer";

const logger = (reducer: IReducer) => {
    const reducerWithLogger = (state: IState, action: IAction) => {
        console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
        console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
        console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state,action));
        return reducer(state,action);
    };
    return reducerWithLogger;
}

const reduceReducers = (...reducers:IReducer[]) => (prevState: IState, action: IAction) =>
    reducers.reduce(
        (newState, reducer) => reducer(newState, action),
        prevState
    );

export default logger(reduceReducers(AuthReducer, WindowReducer));
// export default reduceReducers(reducer);