import React, { CSSProperties } from "react";
import "./Flex.css";

interface IFlexContainerProps {
    style?: CSSProperties;
    fill?: boolean;
    row?: boolean;
    className?: string;
    alignItems?: "flex-start"|"flex-end"|"baseline"|"center"|"stretch";
}

const FlexContainer: React.FC<IFlexContainerProps> = (props) => {
    const styles: CSSProperties = {
        ...props.style
    };

    if(props.fill){
        styles.width = "100%";
        styles.height = "100%";
    }

    if(props.alignItems){
        styles.alignItems = props.alignItems;
    }

    return (
        <div className={`flex-container ${props.row ? "row" : "column"} ${props.className || ""}`} style={styles}>
            {props.children}
        </div>
    );
}


export default FlexContainer;