import React, { useContext, useState } from "react";
import { AppContext } from "../State";
import { ITag } from "../interfaces/IData";
import { ApiSearchTokensRequest } from "../utils/Api";
import DBService from "../services/DBService";
import Tag from "./Tag";
import ComboField from "./ComboField";


const TagSearchbar: React.FC<{onTagChange?(selectedTags: ITag[]):any}> = ({onTagChange}) => {
  const {appState } = useContext(AppContext);

  /* Selected Tags */
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);

  /* Proposed Tags */
  const [proposedTags, setProposedTags] = useState<{
    current: ITag[], 
    requestPending: boolean,
  }>({
    current: [],
    requestPending: false,
  });

  const handleSearchChange = async (e: CustomEvent<any>) => {
    const value = (e.target as HTMLInputElement).value;
    if(value.length === 0) return;

    setProposedTags({...proposedTags, requestPending: true});
    try{
      const tags = await new ApiSearchTokensRequest(value, appState.user.token).run()
      setProposedTags({...proposedTags, requestPending: false, current: tags});
    } catch(error){
      console.log("[ERROR] "+error);
      setProposedTags({...proposedTags, requestPending: false});
    };
  }

  const selectTag = async (tagId: string) => {
    const tag = await DBService.getTag(tagId);
    if(tag){
      const newSelectedTags = [...selectedTags, tag];
      setSelectedTags(newSelectedTags);
      if(onTagChange) onTagChange(newSelectedTags);
    }
  };

  const removeTag = (tagId: string) => {
    console.log(tagId);
    const newSelectedTags = selectedTags.filter((tag)=>tag._id!==tagId);
    setSelectedTags(newSelectedTags);
    if(onTagChange) onTagChange(newSelectedTags);
  }


  return (
    <React.Fragment>
      {
        selectedTags.map((tag, index) => { 
          const colors = ["primary", "secondary", "tertiary", "quaternary"];
          return <Tag color={colors[index%4]} key={tag._id} tag={tag} onClose={removeTag}/>;
        })
      }
      {/* SEARCH BAR */}
      <ComboField loader={proposedTags.requestPending} proposals={proposedTags.current.filter((tag) => selectedTags.find((sTag) => sTag._id===tag._id)===undefined)} onSelect={selectTag} handleChange={handleSearchChange}/>
    </React.Fragment>
  );
}

export default TagSearchbar;