import React, { useContext, useState } from "react";
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonButton, IonIcon, IonInput, IonLabel, IonButtons, IonDatetime, IonTextarea, IonLoading } from "@ionic/react";
import Avatar from "../components/Avatar";
import { AppContext } from "../State";
import FlexContainer from "../layouts/Flex/FlexContainer";
import FlexItem from "../layouts/Flex/FlexItem";

import styles from "./ProfilePage.module.scss";
import { settingsOutline, eyeOutline } from "ionicons/icons";
import ROUTES from "../routes";
import { getLocaleDateString } from "../utils/Dateformat";
import VSpace from "../components/VSpace";
import { ConnectedInput, ConnectedCheckbox } from "../components/Form/ConnectedInput";
import { FormContextProvider, FormContextConsumer } from "../contexts/FormContext";
import { IUserData } from "../interfaces/IData";
import { ApiUpdateProfileRequest, ApiGetAvatarUploadUrlRequest, S3UploadImageRequest } from "../utils/Api";
import { USER_DATA_CHANGED } from "../actiontypes";
import ImagePicker from "../components/ImagePicker";
import SimpleModal from "../components/SimpleModal";
import ProfileContent from "../components/Profile/ProfileContent";

const ProfilePage: React.FC = () => {

  const { appState, dispatch } = useContext(AppContext);
  const userData = (appState.user.data as IUserData);
  // State for form changes
  const [formstate, setFormstate] = useState<{[key: string]: any}>({...userData});
  // State for Profile Preview
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isImagePickerOpen, setImagePickerOpen] = useState(false);
  const [loading, setLoading] = useState<{isOpen: boolean, message?: string}>({isOpen: false, message: "Loading..."});


  const changeInput = (e:Event) => {
    const {name, checked, value} = (e.target as HTMLInputElement);

    // Prepare new state & copy new values
    const newState = {...formstate};
    if(checked !== undefined)
      newState[name] = checked;
    else
      newState[name] = value;

    // Set changed flag according to changes
    if(newState[name] !== userData[name as keyof IUserData])
      newState[name+"_changed"] = true;
    else if(newState[name+"_changed"] !== undefined)
      delete newState[name+"_changed"];

    setFormstate(newState);
  }

  const submitForm = () => {
    const changes = 
      Object.keys(formstate).filter(key => 
        key.endsWith("_changed")
      ).reduce<{[key: string]: any}>((result, keyWithChanged) => {
        const key = keyWithChanged.replace("_changed", "");
        result[key] = formstate[key];
        return result;
      }, {});

    // console.log(changes);

    // Shouldn't happen
    if(Object.keys(changes).length === 0) return;

    setFormstate({...formstate, requestSent: true});
    setLoading({isOpen: true, message: "Saving profile..."});
    new ApiUpdateProfileRequest(appState.user.token, changes).run().then(userData => {
      //TODO: Inform user
      dispatch({
        type: USER_DATA_CHANGED,
        payload: userData
      });
      setFormstate({...userData, requestSent: false});
    }).catch(e => {
      //TODO: Inform user
      console.log(e);
      setFormstate({...formstate, requestSent: false});
    }).finally(() => {
      setLoading({isOpen: false});
    })
  }


  const uploadImage = async (image?: File) => {
    //Close image picker
    setImagePickerOpen(false);

    // Return if 
    if(!image)
      return;

    setLoading({isOpen: true, message: "Uploading image..."});
    //Upload image
    new ApiGetAvatarUploadUrlRequest(appState.user.token, image.name, image.type).run().then(res => {
      new S3UploadImageRequest(res.uploadURL, image).run().then((r) => {
        dispatch({
          type: USER_DATA_CHANGED,
          payload: {...appState.user.data, profilepicture: res.profilepicture}
        });
        console.log(r);
      }).catch((e) => {
        console.error(e);
      }).finally(() => {
        setLoading({isOpen: false});
      })
    })
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>My Profile</IonTitle>
          <IonButtons slot="primary">
            <IonButton color="medium" routerLink={ROUTES.SETTINGS}>
              <IonIcon slot="icon-only" icon={settingsOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="secondary">
            <IonButton color="medium" onClick={() => setPreviewOpen(true)}>
              <IonIcon slot="icon-only" icon={eyeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* MODALS */}
      <SimpleModal title="Your profile" isOpen={isPreviewOpen} onDismiss={() => setPreviewOpen(false)}>
        <ProfileContent user={userData}/>
      </SimpleModal>
      {/* <ProfileViewer title="Your profile" user={userData} isOpen={isPreviewOpen} onDismiss={() => setPreviewOpen(false)}/> */}
      <IonLoading
        isOpen={loading.isOpen}
        message={loading.message}
      />
      <ImagePicker isOpen={isImagePickerOpen} initialsrc={userData.profilepicture} onChange={uploadImage} onDismiss={() => setImagePickerOpen(false)}/>

      {/* CONTENT */}
      <FormContextProvider values={formstate} onChange={changeInput} position="stacked">
        <IonContent className={styles.wrapper}>
          <FlexContainer row>
            <Avatar
              size={120}
              src={userData?.profilepicture}
              onClick={() => setImagePickerOpen(true)}
            />
            <FlexItem flex="1">
              <h2><IonInput name="username" type="text" value={formstate.username} onIonChange={changeInput} style={{"--padding-start":0, "--padding-top":"5px", "--padding-bottom":"5px"}}/></h2>
              <IonTextarea spellcheck autoGrow name="about" onIonChange={changeInput} placeholder="You don't have a bio yet." className={styles.about} value={formstate.about}/>
            </FlexItem>
          </FlexContainer>
          {/* <input
          id="car"
          type="file"
          accept="image/*"
          onChange={getImage}
        /> */}
          <IonList lines="full">
            <ConnectedInput name="fullname" type="text">
                Name
            </ConnectedInput>
            <ConnectedInput name="phone" type="text" inputmode="tel" placeholder={"N/A"}>
                Phone
            </ConnectedInput>
            
            <VSpace small/>
            <FormContextConsumer>
              {(context) => {
                return <IonItem>
                  <IonLabel position={context.position}>Date of Birth</IonLabel>
                  <IonDatetime name="birthdate" placeholder="N/A" value={context.values["birthdate"]} onIonChange={context.onChange} displayFormat={getLocaleDateString(window.navigator.language)}/>
                  {/* <IonCheckbox name="showAge"/> */}
                </IonItem>
              }}
            </FormContextConsumer>
            <ConnectedCheckbox name="showBirthdate">
              Show my birthdate to other users
            </ConnectedCheckbox>
            <ConnectedCheckbox name="newsletterSubscriber">
              I want to receive the newsletter
            </ConnectedCheckbox>
            <IonButton expand="block" color="primary" type="submit" onClick={submitForm} disabled={formstate.requestSent || !Object.keys(formstate).find(k => k.endsWith("_changed"))}>
              Save
            </IonButton>
          </IonList>
        </IonContent>
      </FormContextProvider>
    </IonPage>
  );
}

export default ProfilePage;