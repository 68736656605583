import { IChat, IMessage, IUserData } from "../interfaces/IData";
import DBService from "./DBService";
import API from "../utils/Api";

// export type UserObserverFunc = (chat: IChat) => void;
export type UserObserver = {func: UserObserverFunc, userid: string};

export type UserObserverFunc = (user: IUserData) => void;

class _UserAPI{
  private userobserver: UserObserver[] = [];
  private messageobservers: UserObserverFunc[] = [];


  public subscribeToUser(userid: string, func: UserObserverFunc){
    this.userobserver.push({userid, func: func});
  }
  public unsubscribeFromUser(userid: string, func: UserObserverFunc){
    this.userobserver = this.userobserver.filter(obs => obs.userid !== userid || obs.func !== func)
  }
  private _updateUser(user: IUserData){
    this.userobserver.forEach(obs => {
      if(obs.userid === user._id)
        obs.func(user);
    })
  }
  public getUser(userid: string){
    const run = async () => {
      const dbPromise = new Promise<IUserData>((resolve, reject) => {
        DBService.getUser(userid).then(user => {
          console.log("DBService has user");
          console.log(user);
          console.log(user !== undefined);
          if(user !== undefined) 
            resolve(user)
          else
            console.log("Do nohing");
            // reject();
        })
      });
      const apiPromise = API.getUser(userid).then(user => {
        if(user)
          DBService.putUser(user);
        return user;
      });
    
      console.log(apiPromise);
      const user = await Promise.race([dbPromise, apiPromise])
      if(!user) return null;
      this._updateUser(user);
      return user;
    }

    return run();
  }
}

const UserAPI = new _UserAPI();
export default UserAPI;