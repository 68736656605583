import React from "react";

const VSpace: React.FC<{height?: number, small?: boolean, large?: boolean}> = ({height = 5, small, large}) => {
  if(large)
    height *= 2;
  else if(small)
    height /= 2;

  
  return (
    <p className={`lee-spacer`} style={{height: height+"vh"}}/>
  );
}

export default VSpace;