import React, { useContext, useState } from 'react';
import { IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar, IonButton, IonButtons, IonIcon } from '@ionic/react';
import ChatItem from '../components/ChatItem';
import { ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';
import { AppContext } from '../State';
import { RouteComponentProps } from 'react-router';

const ChatsPage: React.FC<RouteComponentProps> = ({match}) => {
  const {appState, } = useContext(AppContext);
  // const [chats, setChats] = useState<IChat<IUserData>[]>([]);

  if(!appState.user.data?.chats) return null;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Chats</IonTitle>
          
          <IonButtons slot="primary">
            <IonButton color="primary">
              <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical} />
            </IonButton>  
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {
            appState.user.data.chats.map(chat => 
              <ChatItem key={chat} chatID={chat}/>
            )
          }
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ChatsPage;