import { createAnimation } from "@ionic/react";

export const popInAnimation = (duration = 200) => {
  return createAnimation()
      .duration(duration)
      .keyframes([
        {offset: 0, transform: "scale(.8)", opacity: .5},
        {offset: .8, transform: "scale(1.1)"},
        {offset: 1, transform: "scale(1)", opacity: 1 },
      ]);
}

export const fadeInAnimation = (duration = 150) => {
  return createAnimation()
      .duration(duration)
      .keyframes([
        {offset: 0, opacity: 0},
        {offset: 1, opacity: 1}
      ]);
}

export const fadeOutAnimation = (duration = 150) => {
  return fadeInAnimation(duration).direction("reverse");
}