import React from "react";
import { IonContent, IonSlides, IonSlide } from "@ionic/react";

import SimpleModal from "../SimpleModal";

interface IProfileViewer{
  isOpen: boolean;
  onDismiss?(): any;
  title?: string;
  presentingElement?: HTMLElement;
  activeIndex: number;
}
const ProfileSlider: React.FC<IProfileViewer> = ({isOpen, onDismiss, title, children, ...props}) => {  

  const updateIndex = async (sliderRef: HTMLIonSlidesElement) => {
    const active = await sliderRef.getActiveIndex();
    if(props.activeIndex !== active)
      sliderRef.slideTo(props.activeIndex);
      console.log("Updateign");
    sliderRef.update();
  }
  

  return (
    <SimpleModal title={title||"User Profile"} isOpen={isOpen} onDismiss={onDismiss}>
      <IonSlides onIonSlidesDidLoad={function(this: any){updateIndex(this)}} style={{height: "100%"}}>
        {React.Children.map(children, child => (<IonSlide><IonContent>{child}</IonContent></IonSlide>))}
      </IonSlides>
    </SimpleModal>
  );
}

export default ProfileSlider;