import React from "react";
import { IonItem, IonLabel, IonInput, IonCheckbox } from "@ionic/react";

type itemTypes = Pick<HTMLIonItemElement, "lines">;
type labelTypes = Pick<HTMLIonLabelElement, "position">;
type inputTypes = Pick<HTMLIonInputElement, "type"|"inputmode">;

export interface IInput extends itemTypes, labelTypes, inputTypes{
  onChange?(e:any):any;
  name: string;
  className?: string;
  placeholder?: string;
  value?: string;
}

/**
 * Renders an IonItem with Input and Label inside
 * Classname applies to IonItem
 * Position applies to IonLabel
 * Children are rendered inside the label
 */
export const Input: React.FC<IInput> = ({value, type, name, inputmode, position, onChange, placeholder, className, ...props}) => {  
  return (
    <IonItem className={className}>
      <IonLabel position={position}>{props.children}</IonLabel>
      <IonInput type={type} name={name} inputmode={inputmode} value={value} placeholder={placeholder} onIonChange={onChange}/>
    </IonItem>
  );
}


// type checkboxTypes = Pick<HTMLIonCheckboxElement>;
export interface ICheckbox extends itemTypes, labelTypes{
  onChange?(e:any):any;
  name: string;
  className?: string;
  checked?:boolean;
}
/**
 * Renders an IonItem with Checkbox and Label inside
 * Classname applies to IonItem
 * Position applies to IonLabel
 * Children are rendered inside the label
 */
export const Checkbox: React.FC<ICheckbox> = ({checked, name, position, onChange, className, ...props}) => {  
  return (
    <IonItem className={className}>
      <IonCheckbox name={name} checked={checked} onIonChange={onChange}/>
      <IonLabel position={position}>&nbsp;{props.children}</IonLabel>
    </IonItem>
  );
}