import React, { useState, useEffect } from 'react';
import { IChat } from '../interfaces/IData';
import ChatAPI from '../services/ChatAPI';

function useChat(chatID: string) {
  const [chat, setChat] = useState<IChat|null>(null);

  useEffect(() => {
    function handleChange(chat: IChat) {
      console.log("Received chat", chatID);
      setChat(chat);
    }
    
    console.log("Subscribed to chat", chatID);
    ChatAPI.subscribeToChat(chatID, handleChange);
    
    return () => {
      ChatAPI.unsubscribeFromChat(chatID, handleChange);
    };
  }, [chatID]);

  return chat;
}

export default useChat;