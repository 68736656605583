import React from "react";
import { FormContextConsumer } from "../../contexts/FormContext";
import VSpace from "../../components/VSpace";
import { IonItem, IonLabel, IonButton, IonCheckbox, IonIcon } from "@ionic/react";
import {lockClosed, happy, fingerPrint} from "ionicons/icons";

const RegisterForm3: React.FC = () => {
  return (
    <FormContextConsumer>
        {(contextProps) => (
          <React.Fragment>
            <h1>About your data</h1>
            <VSpace/>
            <IonItem lines="none">
                <IonIcon icon={lockClosed} slot="start"/>
                <p>We don't use any personalized tracking.</p>
            </IonItem>
            <br/>
            <IonItem lines="none">
                <IonIcon icon={fingerPrint} slot="start"/>
                <p>We do use cookies to store your login information and settings on your device.</p>
            </IonItem>
            <br/>
            <IonItem lines="none">
                <IonIcon icon={happy} slot="start"/>
                <p>Your profile and personalized information is not shared with anybody.</p>
            </IonItem>
            <VSpace small/>
            <p className="text-left">
              You want to know the details? Click here (Full privacy policy).
            </p>

            <IonItem lines="none">
              <IonCheckbox onIonChange={contextProps.onChange} name="termsAccepted" checked={contextProps.values["termsAccepted"]}/>
              <IonLabel>&nbsp;I agree, ...</IonLabel>
            </IonItem>
            <VSpace small/>
            <IonButton expand="block" disabled={contextProps.values["formSent"]} onClick={() => {console.log(contextProps); if(contextProps && contextProps.onNavigation) contextProps.onNavigation({direction: "forwards"})}}>Continue</IonButton>          
          </React.Fragment>
        )}
    </FormContextConsumer>
  );
}

export default RegisterForm3;