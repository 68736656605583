import React, { useContext } from "react";
import { AppContext } from "./State";
import { Redirect, Route, Switch } from "react-router";
import ROUTES from "./routes";
import MenteeContainer from "./pages/MenteeContainer";
import { IonReactRouter } from "@ionic/react-router";
import AuthContainer from "./pages/AuthContainer";

const AppRootOutlet: React.FC = () => {
  const {appState} = useContext(AppContext);

  let outlet;
  if(appState.user.loggedIn)
    outlet = <Route path={ROUTES.MENTEE} component={MenteeContainer}/>;
  else
    outlet = <Redirect to={ROUTES.AUTH}/>;
  
  return (
    <React.Fragment>
      <IonReactRouter>
        <Switch>
          <Route path={ROUTES.AUTH} component={AuthContainer}/>
          {outlet}
        </Switch>
      </IonReactRouter>
    </React.Fragment>
  );
}

export default AppRootOutlet;