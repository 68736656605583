import React from "react";
import styles from "./ImgWrapper.module.scss";

const ImgWrapper: React.FC<{maxHeight: string; color?: string; background?: string}> = ({maxHeight, color, background, children}) => {
  const inlineStyle = {
    maxHeight: maxHeight,
    background: background || `var(--ion-color-${color||"medium"})`
  }
  return (
    <div className={styles.wrapper} style={inlineStyle}>
      {children}
    </div>
  );
}

export default ImgWrapper;