import React, { useState, useContext, useEffect } from "react";
import { IonHeader, IonToolbar, IonTitle, IonPage, IonContent, IonListHeader, IonItem, IonLabel, IonSelect, IonSelectOption, IonButton } from "@ionic/react";
import VSpace from "../../components/VSpace";

import "./FindMentor.scss";
import MentorItem from "../../components/MentorItem";
import { ApiSearchMentorsRequest } from "../../utils/Api";
import { AppContext } from "../../State";
import { ITag, IUserData } from "../../interfaces/IData";
import IntelligentList from "../../components/IntelligentList";
import { TOKEN_EXPIRED } from "../../actiontypes";
import TagSearchbar from "../../components/TagSearchbar";
import ProfileSlider from "../../components/Profile/ProfileSlider";
import ProfileContent from "../../components/Profile/ProfileContent";
import MentorContent from "../../components/Profile/MentorDetails";
import RequestForm from "../../components/Profile/RequestForm";

const FindMentor: React.FC = () => {
  const {appState, dispatch} = useContext(AppContext);

  const genderIdentities = ['gay', 'lesbian', 'bisexual', 'pansexual', 'asexual', 'questioning', 'trans', 'inter', 'non-binary', 'non-conforming', 'other'];
  
  /* Proposed Mentors */
  const [mentors, setMentors] = useState<{
    current: IUserData[],
    requestPending: boolean,
  }>({
    current: [],
    requestPending: false,
  });
  const [identities, setIdentities] = useState<string[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);

  const [mentorModal, openMentorModal] = useState<{mentor?:IUserData, open:boolean}>({
    open: false,
  });

  const [requestMentor, setRequestMentor] = useState<IUserData>();

  /* Fetch new mentors when selected props change */
  const tagChanged = (newTags: ITag[]) =>{
    // if(newTags.length === 0) return;
    setTags(newTags);  
  };

  const identitiesChanged = (e: Event) => {
    setIdentities((e.target as any).value);
  }

  useEffect(() => {
    if(!tags.length) return;
    // Set loading flag
    setMentors(m => ({...m, requestPending: true}));

    // Request matches
    const request = new ApiSearchMentorsRequest(tags.map(tag => tag._id), identities, appState.user.token);
    request.run(matches => {
      setMentors({current: matches, requestPending: false});
    }, (error) => {
      if(error.status === 401) dispatch({type: TOKEN_EXPIRED});
    });

    //Unsubscribe on change
     return () => {
       request.unsubscribeAll();
     }
  }, [identities, tags]);
  
  /* Debug output */
  useEffect(() => {
    console.log(mentors);
  }, [mentors]);

  const ref = React.createRef<any>();
  
  return (
    <IonPage ref={ref}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Find a mentor
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <ProfileSlider
          activeIndex={mentors.current.findIndex(mentor => mentor._id === mentorModal.mentor?._id)}
          isOpen={mentorModal.open} 
          onDismiss={() => openMentorModal({open: false})}>
            {mentors.current.map(mentor => (
              <MentorContent key={mentor._id} user={mentor}>
                <IonButton expand="block" color="tertiary" fill="outline" onClick={() => setRequestMentor(mentor)}>Get in Touch</IonButton>
              </MentorContent>
            ))}
        </ProfileSlider>

        <RequestForm 
          isOpen={requestMentor!==undefined} 
          mentor={requestMentor} 
          onDismiss={() => setRequestMentor(undefined)} 
          onSuccess={() => setRequestMentor(undefined)} 
          tags={tags}
        />
        


        <VSpace small/>
        <div style={{padding:"0 10px"}}>
          <IonItem>
            <IonLabel>Gender Identity</IonLabel>
            <IonSelect selectedText="" placeholder="Click here to choose" multiple onIonChange={identitiesChanged}>
              {genderIdentities.map((id, index) => (<IonSelectOption key={index}>{id}</IonSelectOption>))}
            </IonSelect>
          </IonItem>
        </div>
        {/* DISPLAY SELECTED TAGS */}
        <TagSearchbar onTagChange={tagChanged}/>
        <VSpace small/>
        {/* <IonButton expand="block" >Find a mentor</IonButton>   */}

        {/* MENTORS LIST */}
        <IntelligentList loading={mentors.requestPending} isEmptyElement={<br/>}>
          <IonListHeader>{mentors.current.length===0? "No matching mentors" : mentors.current.length===1? "1 matching mentor" : `${mentors.current.length} matching mentors`}</IonListHeader>
          {mentors.current && mentors.current.map((mentor, index) => <MentorItem onClick={() => openMentorModal({open: true, mentor})} key={mentor._id} user={mentor}/>)}
        </IntelligentList>
      </IonContent>
    </IonPage>
  );
}

export default FindMentor;