import React, { useState } from 'react';
import { IonInput, IonItem, IonLabel, IonButton, IonIcon, IonAlert } from '@ionic/react';
import Logo from '../components/Logo';
import ROUTES from '../routes';
import LeeModal from '../layouts/ModalPage';
import Decorator from '../components/Decorator';
import SimpleHeader from '../components/SimpleHeader';
import { RouteComponentProps } from 'react-router';
import VSpace from '../components/VSpace';
import { verifyEmail } from '../utils/Verify';

const ForgotModal: React.FC<RouteComponentProps> = ({history}) => {
    const [state, setState] = useState({
        email: "",
        email_valid: true,
        sent: false,
    });
    const [alertIsOpen, showAlert] = useState(false);

    const handleChange = (e: CustomEvent<KeyboardEvent>) => {
        const target = (e.target as HTMLInputElement);

        // Provide instant feedback if input marked invalid
        const valid = (!state.email_valid && verifyEmail(target.value) === "ok") ? {email_valid: true} : {};

        setState({
            ...state,
            email: target.value,
            ...valid
        });
    }

    const submitForget = async (e:any) => {
        // Check if valid email address provided
        if(verifyEmail(state.email) !== "ok") {
           setState({
               ...state,
               email_valid: false,
           });
           return;
        }

        setState({...state, sent: true});

        // TODO: Sent request to reset password
        await new Promise((resolve, reject) => setTimeout(resolve, 1000));

        showAlert(true);
    }

    const handleBack = () => {
        history.push(ROUTES.LOGIN)
    };


    return (
        <LeeModal contentClass="text-center" header={<SimpleHeader left={<IonButton fill="clear" onClick={handleBack}><IonIcon slot="icon-only" name="arrow-back"/></IonButton>} right={<Logo width="70px"/>}/>}>
            <h1>Forgot your password?</h1>
            <p>&nbsp;</p>                
            <p>&nbsp;</p>
            <p className="text-left">Please enter the Email-Address that you use for your account.</p>             
            <IonItem>
                    <IonLabel 
                        position="floating"
                        color={state.email_valid ? undefined : "danger"}
                        >E-Mail</IonLabel>
                    <IonInput
                        type="email" 
                        name="email" 
                        inputmode="email" 
                        required
                        onIonInput={handleChange}
                        />
            </IonItem>
            <IonButton disabled={state.sent} class="lee-login-submit" expand="block" onClick={submitForget} type="submit">Login</IonButton>

            <IonAlert 
                isOpen={alertIsOpen}
                onDidDismiss={() => history.push(ROUTES.LOGIN)}
                header={"Email sent"}
                message="If the email-address was correct, we sent you a link to reset your password."
                buttons={["OK"]}
            />

            <VSpace />

            <Decorator absolute bottom/>
        </LeeModal>
    );
};

export default ForgotModal;