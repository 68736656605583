import React from "react";

const TimeString: React.FC<{date: Date, options?:Intl.DateTimeFormatOptions}> = ({date, options}) => {
  const pOptions = options || {hour: "2-digit", minute: "2-digit"};

  return (
    <React.Fragment>
      {date.toLocaleTimeString([], pOptions)}
    </React.Fragment>
  );
}

export default TimeString;