import React from "react";
// import { NavigationEvent } from "../Interfaces/IEvents";


export interface IFormContext extends Pick<HTMLIonLabelElement, "position">{
  values?: any;
  onChange?(e:any):any;
  onBlur?(e:any):any;
  onNavigation?(e:any):any;
}

const FormContext = React.createContext<IFormContext>({});

export const FormContextProvider: React.FC<IFormContext> = ({children, ...props}) => { return <FormContext.Provider value={props}>{children}</FormContext.Provider>};
export const FormContextConsumer = FormContext.Consumer;


// interface IWithEntityData<T, C>{
//     (Component: any): (React.FC<T>);
// }

// interface IContextProps{
//     source: any;
//     name: string;
//     value: string|number|any;
// }

// interface IContextComponentProps extends IContextProps{
//     valid: boolean;
// }

// export interface IProviderProps{
//     source: any;
//     onChange(e: CustomEvent<any>): any;
//     onBlur?(e: MouseEvent): any;
//     onNavigate?(e: NavigationEvent): any;
// }

// type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// export function withAppContext<
//   P extends { appContext?: AppContextInterface },
//   R = Omit<P, 'appContext'>
//   >(
//   Component: React.ComponentClass<P> | React.StatelessComponent<P>
//   ): React.SFC<R> {
//   return function BoundComponent(props: R) {
//     return (
//       <FormContext.Consumer>
//         {value => <Component {...props} appContext={value} />}
//       </FormContext.Consumer>
//     );
//   };
// }


// export const FormProvider: React.FC<IProviderProps> = (props) => {
//         return (
//             <FormContext.Provider value={{
//                 source: props.source,
//                 onChange: props.onChange,
//                 onBlur: props.onBlur,
//                 onNavigate: props.onNavigate,
//             }}>
//                 { props.children }
//             </FormContext.Provider>
//         );
// }
