import React, { useState, useContext } from 'react';
import { Route, Redirect, Switch, RouteComponentProps } from 'react-router';
import ROUTES from '../routes';
import LoginModal from './LoginModal';
import "./Auth.scss";
import RegisterModal from './RegisterModal';
import ForgotModal from './ForgotModal';
import ActivateForm from './registration/ActivateForm';
import { AppContext } from '../State';

const AuthContainer: React.FC<RouteComponentProps> = ({location}) => {
    const {appState} = useContext(AppContext);
    const [state, setState] = useState({
        redirect: false,
        redirectTarget: ""
    });


    const {loggedIn} = appState.user;
    const status = appState.user.data?.status;

    console.log(loggedIn);

    if(state.redirect){
        // DO nothing
    }
    else if(status === "ACTIVATION_PENDING" && location.pathname !== ROUTES.ACTIVATE_ACCOUNT){
        setState({
            ...state,
            redirect: true,
            redirectTarget: ROUTES.ACTIVATE_ACCOUNT,
        })
    }
    // else if(loggedIn){
    //     setState({
    //         ...state,
    //         redirect: true,
    //         redirectTarget: "/",
    //     })
    // }

    return (
        <div style={{ height: "100%", width:"100%", background: "linear-gradient(130deg, #FC5F45 0%, #FEB960 33%, #34BA65 66%, #3F85CC 100%)" } }>
            <Switch>
                <Route path={ROUTES.LOGIN} exact component={LoginModal}/>
                <Route path={ROUTES.REGISTER+"/:formid"} component={RegisterModal}/>
                <Route path={ROUTES.REGISTER} exact component={RegisterModal}/>
			    <Route path={ROUTES.ACTIVATE_ACCOUNT} component={ActivateForm}/>
                <Route path={ROUTES.FORGOT_PASSWD} exact component={ForgotModal}/>
                <Route render={() => (<Redirect to={ROUTES.LOGIN}/>)}/>
            </Switch>
            {state.redirect && <Route render={() => (<Redirect to={state.redirectTarget}/>)} /> }
        </div>
    );
};

export default AuthContainer;