import React, { useState, useEffect } from "react";
import { IUserData, ITag } from "../../interfaces/IData";
import Tag from "../Tag";
import VSpace from "../VSpace";
import DBService from "../../services/DBService";
import ProfileContent from "./ProfileContent";


interface IMentorViewer{
  user?:IUserData;
}

const MentorContent: React.FC<IMentorViewer> = ({user, children, ...props}) => {
  const [tags, setTags] = useState<ITag[]>([]);

  
  useEffect(() => {
    if(user?.tags)
    DBService.getTags(user.tags).then(tags => setTags(tags));
  }, [user]);
  
  
  if(!user) return null;
  return (
    <ProfileContent user={user}>
      <h3>Knowledge in:</h3>
      <p>{tags.map(tag => <Tag key={tag._id} tag={tag}/>)}</p>
      <VSpace small/>
      {children}
    </ProfileContent>
  );
}

export default MentorContent;