import React, { useContext, useState, useEffect } from "react";
import ChatPage from "./ChatPage";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../../State";
import { IMessage, IUserData, IChat } from "../../interfaces/IData";
import DBService from "../../services/DBService";
import ChatAPI from "../../services/ChatAPI";
import UserAPI from "../../services/UserAPI";
import useChat from "../../hooks/useChat";
import { TOGGLE_TABBAR } from "../../actiontypes";

const ChatPageC: React.FC<RouteComponentProps> = ({match}) => {  
  const chatID = (match.params as {chatid: string}).chatid;
  
  const {appState, dispatch } = useContext(AppContext);
  // const [chat, setChat] = useState<IChat>();
  const chat = useChat(chatID);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [partner, setPartner] = useState<IUserData>();

  const [formstate, setFormstate] = useState<{loading: boolean; value: string}>({ loading: false, value: ""});
  const scrollRef = React.createRef<HTMLIonInfiniteScrollElement>();

  useEffect(() => {
    dispatch({type: TOGGLE_TABBAR});

    return () => {
      dispatch({type: TOGGLE_TABBAR});
    }
  }, [])

  useEffect(() => {
    if(!chat) return;
    const chatPartnerID = chat.users.find(userID => userID !== appState.user.data?._id)!;

    // Async func for getting chat messages from DB
    const getChatMessages = () => {
      DBService.getChatMessages(chat._id, 0, 10).then(messages => {
        console.log("Got messages");
        console.log(messages)
        setMessages(messages);
      })
    }

    // Get Information
    getChatMessages();
    UserAPI.getUser(chatPartnerID).then((user) => {
      if(user)
        setPartner(user);
    })
    
    //Subscribe to future updates
    ChatAPI.listenToMessageUpdate(getChatMessages);

    return () => {
      ChatAPI.dismissMessageUpdate(getChatMessages);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  const onChange = (e: Event) => {
    setFormstate({...formstate, value: (e.target as HTMLInputElement).value})
  }
  const onSendMessage = () => {
    if(!formstate.value) return;

    setFormstate({...formstate, loading: true});
    ChatAPI.sendMessage(chatID, formstate.value).then(() => {
      setFormstate({value: "", loading: false});
    });
  }

  const loadMoreMessages = (event:any) => {
    if(!chat) return;

    console.log("Loading more messages");
    DBService.getChatMessages(chat._id, messages.length, 5).then(olderMessages => {
      setMessages([...olderMessages, ...messages]);
      // event.target.complete();
      console.log("COmpleted");
    })
  }

  if(scrollRef.current) console.log(scrollRef.current.scrollTop);
  else console.log("No ref!");
  if(!chat || !partner) return null;
  return (
    <ChatPage 
      partner={partner} 
      messages={messages} 
      sendMessageHandler={onSendMessage} 
      handleInputChange={onChange} 
      inputValue={formstate.value} 
      requestSent={formstate.loading}
      loadMoreMessages={loadMoreMessages}
      scrollContainerRef={scrollRef}
    />
  );
}

export default ChatPageC;