import React, { useState, useEffect } from 'react';
import { Plugins, KeyboardInfo } from '@capacitor/core';

const { Keyboard } = Plugins;

function useKeyboardState() {
  const [state, setState] = useState<"open"|"closed"|null>();

  useEffect(() => {
    let handleClose = () => {
      console.log("Keyboard closed")
      setState("closed");
    }
    let handleOpen = (e: KeyboardInfo) => {
      console.log("Keyboard openened");
      setState("open")
    }
    
    Keyboard.addListener("keyboardWillShow", handleOpen);
    Keyboard.addListener("keyboardWillHide", handleClose);

    return () => {
      handleOpen = ()=>{};
      handleClose = ()=>{};
    };
  });

  return state;
}

export default useKeyboardState;