import React, { CSSProperties } from "react";
import "./Flex.css";

interface IFlexItemProps {
    flex?: string;
    height?: string;
    className?: string;
    align?: string;
}

const FlexItem: React.FC<IFlexItemProps> = (props) =>{
    const styles: CSSProperties = {
        flex: props.flex || "0 0 auto",
        ...(props.height && {height: props.height}),
        ...(props.align && {alignSelf: props.align})
    };
    
    return (
        <div className={`flex-item ${props.className || ""}`} style={styles}>
            {props.children}
        </div>
    );
}

export default FlexItem;