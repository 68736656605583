export type VerificationResult = "error"|"warning"|"ok";


export const verifyForm = (formData: any[]) => {
    return formData.map<VerificationResult>(dataset => {
        return verifySet(dataset.type, dataset.value);
    })
}

export const verifySet = (type: string, value:any) => {
    switch(type){
        case "fullname": return verifyFullname(value);
        case "email": return verifyEmail(value);
        default: return "warning";
    } 
}

export const verifyFullname = (input: string) => {
    const hardCheck = /^.{2,}(\s.{2,})+/;
    if(!hardCheck.test(input)) return "error";
    
    const suspiciousCheck = /[.,:;@<>|_#*+~^°!"§$%&()=?{}/\\[\]]/;
    if(suspiciousCheck.test(input)) return "warning";

    return "ok";
}

export const verifyEmail = (input: string) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

    return regex.test(input) ? "ok" : "error";
}

export const verifyUsername = (input: string) => {
    return "ok";
}

export const verifyBirtdate = (input: string) => {
    return "ok";
}