import React from "react";
import { IonItem, IonSpinner } from "@ionic/react";

const LoadingItem: React.FC = () => {
  
  return (
    <IonItem><IonSpinner  style={{margin: "0 auto"}}/></IonItem>
  );
}

export default LoadingItem;