import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
   IonRouterOutlet,
   IonTabs,
   IonTabBar,
   IonTabButton,
   IonIcon,
   IonLabel,
} from '@ionic/react';

import ROUTES from "../routes";
import FindMentor from './mentee/FindMentor';
import ChatsPage from './ChatsPage';
import ProfilePage from './ProfilePage';
import Settings from './mentee/SettingsPage';
import { AppContext } from '../State';
import API from '../utils/Api';
import ChatPageC from './mentee/ChatPage.container';
import useKeyboardState from '../hooks/useKeyboardState';
import { people, chatbubbles, newspaper, personCircle } from 'ionicons/icons';


const MenteeContainer: React.FC = () => {

  const {appState, dispatch} = useContext(AppContext);
  const keyboardState = useKeyboardState();
  const tabbarSlot = (keyboardState === "open" || appState.window.tabbarVisible === false) ? undefined : "bottom";

  useEffect(() => {
    API.authtoken = appState.user.token;
  }, [appState.user.token]);

   
   

return (
  <React.Fragment>
      {/* Redirect */}
    <Route exact path={ROUTES.MENTEE} render={() => <Redirect to={ROUTES.ME_MENTORS} />}/>
    <IonTabs>
      <IonRouterOutlet>
        <Route path={ROUTES.ME_MENTORS} component={FindMentor} exact={true} />
        <Route path={ROUTES.ME_CHATS} component={ChatsPage} exact={true} />
        <Route path={ROUTES.ME_CHATS+"/:chatid"} component={ChatPageC} />
        <Route path={ROUTES.ME_ME} component={ProfilePage} exact/>
        <Route path={ROUTES.SETTINGS} component={Settings}/>
        <Route path={ROUTES.ME_EDIT} component={ProfilePage} />
      </IonRouterOutlet>


      <IonTabBar slot={tabbarSlot}>
        {appState.user.data?.role === "user" &&
        <IonTabButton tab="tab1" href={ROUTES.ME_MENTORS}>
          <IonIcon icon={people} />
          <IonLabel>Mentors</IonLabel>
        </IonTabButton>}
        <IonTabButton tab="tab2" href={ROUTES.ME_CHATS}>
          <IonIcon icon={chatbubbles} />
          <IonLabel>Chats</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab3" href={ROUTES.ME_NEWS}>
          <IonIcon icon={newspaper} />
          <IonLabel>News</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab4" href={ROUTES.ME_ME}>
          <IonIcon icon={personCircle} />
          <IonLabel>Me</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  </React.Fragment>
);

}

// export default withRouter(MenteeContainer);
export default MenteeContainer;
