import React from "react";
import { FormContextConsumer } from "../../contexts/FormContext";
import { IonItem, IonLabel, IonInput, IonButton } from "@ionic/react";
import VSpace from "../../components/VSpace";


const RegisterForm1: React.FC = () => {
  return (
      <FormContextConsumer>
          {(contextProps) => (
              <React.Fragment>
                  <h1>Registration</h1>
                  <p>To complete registration, please fill out the following form:</p>
                  <VSpace small/>
                  <IonItem>
                      <IonLabel position="floating">Email</IonLabel>
                      <IonInput 
                          type="email"
                          name="email"
                          value={contextProps?.values["email"]}
                          inputmode="email"
                          onIonChange={contextProps?.onChange}
                          />
                  </IonItem>
                  <p className="text-left auth-input-description">We only use your email-address to contact you when neccessary, for example if you forgot your password.</p>
                  <VSpace />
                  <IonItem>
                      <IonLabel position="floating">Password</IonLabel>
                      <IonInput 
                          type="password"
                          name="password"
                          value={contextProps?.values["password"]}
                          inputmode="text"
                          onIonChange={contextProps?.onChange}
                          />
                  </IonItem>
                  <p className="text-left auth-input-description">Your password must have atleast 7 characters and contain a letter, a number and a special char.</p>
                  <IonItem>
                      <IonLabel position="floating">Repeat Password</IonLabel>
                      <IonInput 
                          type="password"
                          name="password2"
                          value={contextProps?.values["password2"]}
                          inputmode="text"
                          onIonChange={contextProps?.onChange}
                          />
                  </IonItem>
                  <VSpace />
                  <IonButton expand="block" onClick={() => {console.log(contextProps); if(contextProps && contextProps.onNavigation) contextProps.onNavigation({direction: "forwards"})}}>Continue</IonButton>
              </React.Fragment>
          )}
      </FormContextConsumer>
  );
}

export default RegisterForm1;