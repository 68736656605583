import React from "react";
import { FormContextConsumer } from "../../contexts/FormContext";
import VSpace from "../../components/VSpace";
import { IonItem, IonLabel, IonInput, IonDatetime, IonButton } from "@ionic/react";
import { getLocaleDateString } from "../../utils/Dateformat";

const RegisterForm2: React.FC = () => {
  return (
      <FormContextConsumer>
          {(contextProps) => (
              <React.Fragment>
                  <h1>About You</h1>
                  <IonItem>
                      <IonLabel position="floating">Alias</IonLabel>
                      <IonInput 
                          type="text"
                          name="username"
                          value={contextProps?.values["username"]}
                          onIonChange={contextProps?.onChange}
                          />
                  </IonItem>
                  <p className="text-left auth-input-description">This will be the name that other users see.</p>
                  <IonItem>
                      <IonLabel position="floating">Full Name</IonLabel>
                      <IonInput 
                          type="text"
                          name="fullname"
                          value={contextProps?.values["fullname"]}
                          onIonChange={contextProps?.onChange}
                          />
                  </IonItem>
                  <IonItem>
                      <IonLabel position="floating">Date of Birth</IonLabel>
                      <IonDatetime onIonChange={contextProps?.onChange} displayFormat={getLocaleDateString(window.navigator.language)}/>
                  </IonItem>
                  <p className="text-left auth-input-description">
                    Users won't be able to see your real name or date of birth, but the information is used to protect your account and the platform.
                  </p>
                  <VSpace small/>
                  <IonButton expand="block" onClick={() => {console.log(contextProps); if(contextProps && contextProps.onNavigation) contextProps.onNavigation({direction: "forwards"})}}>Continue</IonButton>          
              </React.Fragment>
          )}
      </FormContextConsumer>
  );
}

export default RegisterForm2;