import React, { useState, useContext, MouseEvent } from "react";
import { IonItem, IonLabel, IonInput, IonButton, IonIcon } from "@ionic/react";
import VSpace from "../../components/VSpace";
import LeeModal from "../../layouts/ModalPage";
import SimpleHeader from "../../components/SimpleHeader";
import Logo from "../../components/Logo";
import { AppContext } from "../../State";
import { RouteComponentProps } from "react-router";
import ROUTES from "../../routes";
import Decorator from "../../components/Decorator";
import { ApiVerifyMailRequest } from "../../utils/Api";


const ActivateForm: React.FC<RouteComponentProps> = ({history}) => {
  const [state, setState] = useState({
      "verificationCode": "",
      "verificationCode_valid": false
  });

  const { appState } = useContext(AppContext);
    
  const handleChange = (e: CustomEvent<any>) => {
    const target = (e.target as HTMLInputElement);
    const valid = (target.value.length === 4);
    setState({...state, verificationCode: target.value, verificationCode_valid: valid});
  }

  const handleSubmit = async (e: MouseEvent<any>) => {
      if(state.verificationCode_valid){
        new ApiVerifyMailRequest(state.verificationCode, appState.user.token).run(response => {
          if(response.message === "VERIFICATION_SUCCESSFUL"){
            console.log("Verification successful");
            history.push(ROUTES.MENTEE);
          }
        }, (error) => {
          console.error("[ERROR] "+error);
        });
    }
  }

  React.useEffect(() => {
    console.log("Running");
    if(!appState.user.loggedIn || appState.user.data?.status === "ACTIVE") history.push(ROUTES.LOGIN);
  }, [appState.user, history]);


  return (
   <LeeModal contentClass="text-center" header={<SimpleHeader left={<IonButton fill="clear" onClick={() => history.push(ROUTES.AUTH)}><IonIcon slot="icon-only" name="arrow-back"/></IonButton>} right={<Logo width="50px"/>}/>}>

				<h1>Activate your account</h1>
				<p>To complete registration, please enter the code we sent to your email-address or click the link:</p>
				<VSpace small/>
				<IonItem>
					 <IonLabel position="floating">Code</IonLabel>
                <IonInput 
						  type="number"
                    name="vCode"
                    max="999999"
                    maxlength={6}
						  value={state.verificationCode}
						  inputmode="email"
						  onIonChange={handleChange}
						  />
				</IonItem>
				<VSpace />
				<IonButton expand="block" disabled={!state.verificationCode_valid} onClick={handleSubmit}>Activate</IonButton>
				<VSpace />
            <Decorator absolute bottom />
		  </LeeModal>
  );
}

export default ActivateForm;