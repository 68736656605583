import React from "react";
import "./SimpleHeader.scss";
import FlexContainer from "../layouts/Flex/FlexContainer";
import FlexItem from "../layouts/Flex/FlexItem";

const SimpleHeader: React.FC<{absolute?:boolean; left?:React.ReactNode; right?:React.ReactNode}> = (props) => {
    return <div className={`lee-header-simple ${props.absolute?"absolute":""}`}>
            <FlexContainer row alignItems="center">
                <FlexItem>{props.left}</FlexItem>
                <FlexItem flex="1">{props.children}</FlexItem>
                <FlexItem>{props.right}</FlexItem>
            </FlexContainer>
        </div>;
}

export default SimpleHeader;