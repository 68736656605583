import React from "react";

import styles from "./ProfileContent.module.scss";
import { IUserData } from "../../interfaces/IData";
import Avatar from "../Avatar";
import ContentWrapper from "../ContentWrapper";
import ImgWrapper from "../ImgWrapper";

interface IProfileContent{
  user: IUserData;
}
const ProfileContent: React.FC<IProfileContent> = ({user, ...props}) => {

  let ageInYears;
  if(user.showBirthdate && user.birthdate){
    const bd = new Date(user.birthdate);
    const now = new Date();
    ageInYears = Math.floor((now.getTime()-bd.getTime()) / 31557600000); // Divide delta in ms by 1000*60*60*24*365.25
  }
  

  return (
      <React.Fragment>
        <ImgWrapper maxHeight="60%" color="medium">
          <Avatar fill src={user.profilepicture}/>
        </ImgWrapper>
        <ContentWrapper className={`text-left ${styles.profileContent}`}>
          <h1>{user.username} <span className="placeholder">{ageInYears && `(${ageInYears}y)`}</span></h1>
          <div className={styles.profileAbout}>
            {/* Add invisible whitespace to keep double, triple, ... linebreaks */}
            {user.about?.replace(/\n/g, "\u00a0\n").split("\n").map((line, index) => <p key={line+index}>{line}</p>)}
          </div>
          {props.children}
        </ContentWrapper>
      </React.Fragment>
  );
}

export default ProfileContent;