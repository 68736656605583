import React from "react";

import styles from "./Message.module.scss";

interface IMessageProps{
  text: string;
  time?: React.ReactNode|string;
  side: "left"|"right";
  color?: string;
}
const Message: React.FC<IMessageProps> = ({text, time, side, color}) => {
  
  const messageStyle={
    background: `var(--ion-color-${color || "light"})`,
    borderColor: `var(--ion-color-${color || "light"})`,
  }

  const timeStyle={
    color: messageStyle.background+"-contrast", 
    opacity: ".6", 
    fontSize: "var(--font-size-small)"
  }

  return (
    <div className={`${styles.wrapper} ${styles[side]}`}>
      <div style={messageStyle} className={styles.message}>
        {text}
        {time && <p className="text-right" style={timeStyle}>{time}</p>}
      </div>
    </div>
  );
}

export default Message;