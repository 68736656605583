import React, { useState, useEffect, useContext } from 'react';
import { IUserData } from '../interfaces/IData';
import UserAPI from '../services/UserAPI';
import { AppContext } from '../State';

function useUserID() {
  const {appState} = useContext(AppContext);
  const [ID, setID] = useState<string|null>(null);

  // useEffect(() => {
  //   function handleChange(user: IUserData) {
  //     setID(appState.user.data?._id);
  //   }
    
  //   UserAPI.subscribeToUser(userID, handleChange);
  //   return () => {
  //     UserAPI.unsubscribeFromUser(userID, handleChange);
  //   };
  // });

  return appState.user.data?._id||null;
}

export default useUserID;