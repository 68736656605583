import React from "react";
import { IonItem, IonTextarea, IonButtons, IonButton, IonIcon, IonSpinner } from "@ionic/react";
import { send } from "ionicons/icons";

interface IInputProps{
  onChange(e: Event): any;
  onSubmit(): any;
  value: string;
  loading?: boolean;
}

const MessageInput: React.FC<IInputProps> = ({onChange, onSubmit, value, loading}) => {
  if(loading) console.log("Request loading!");
  return (
    <IonItem lines="none"> 
      <IonTextarea name="chatbox" onIonChange={onChange} value={value} autoGrow rows={1}/>
      <IonButtons slot="end" >
        {loading ? 
          <IonSpinner /> :
          <IonButton color="medium" onClick={onSubmit}>
            <IonIcon icon={send} slot="icon-only"/>
          </IonButton>
        }
      </IonButtons>
    </IonItem>
  );
}

export default MessageInput;