import { IReducer } from "./interfaces/IState";
import { LOGIN_SUCCESSFUL, TOKEN_EXPIRED, USER_DATA_CHANGED, LOGOUT_SUCCESSFUL } from "./actiontypes";

export const AuthReducer: IReducer = (state, action) => {
  switch(action.type){
    case LOGIN_SUCCESSFUL:
      return{
        ...state,
        user: {
          ...state.user,
          loggedIn: true,
          data: action.payload.user,
          token: action.payload.token,
          tokenExpired: false,
          storeSession: action.payload.storeSession
        }
      };
    case USER_DATA_CHANGED:
      return {
        ...state,
        user: {
          ...state.user,
          data: action.payload,
        }
      };
    case TOKEN_EXPIRED:
      return {
        ...state,
        user: {
          ...state.user,
          tokenExpired: true,
        }
      };
    case LOGOUT_SUCCESSFUL:
      return {
        ...state,
        reset: true,
      }
    default: return state;
  }
}