const ROUTES = {
    ROOT: "/",
    MENTEE: "/",
    ME_MENTORS: "/mentors",
    ME_CHATS: "/chats",
    ME_NEWS: "/news",
    ME_ME: "/me",
    ME_EDIT: "/me/personal",
    SETTINGS: "/settings",
    
    MENTOR: "/",
    MO_REQUESTS: "/requests",
    MO_CHATS: "/chats",
    MO_NEWS: "/news",
    MO_ME: "/me",

    AUTH: "/auth",
    LOGIN: "/auth/login",
    REGISTER: "/auth/register",
    ACTIVATE_ACCOUNT: "/auth/activate",
    FORGOT_PASSWD: "/auth/forgot",
};

export default ROUTES;