import React from "react";
import { IonHeader, IonTitle, IonButton, IonIcon, IonButtons, IonContent, IonModal, IonToolbar } from "@ionic/react";
import { close } from "ionicons/icons";


type x = HTMLIonModalElement;
interface IModal{
  title?: string;
  isOpen: boolean;
  onDismiss?(e:any): any;
  header?:React.ReactNode
}

const SimpleModal: React.FC<IModal> = ({children, header, isOpen, title, onDismiss, ...props}) => {
  return (
    <IonModal isOpen={isOpen} swipeToClose={true} onDidDismiss={onDismiss} {...props}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon slot="icon-only" icon={close}/>
            </IonButton>
          </IonButtons>
          {header}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {children}
      </IonContent>
    </IonModal>
  );
}

export default SimpleModal;